import React, { useState, useRef, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import classNames from "classnames";

import { getPPCLinks, getPASeoLinks } from "../../Utils/Helpers";

import "./WhiteNavbar.scss";

const ppc_links = getPPCLinks();
const paseo_links = getPASeoLinks;

const WhiteNavbar = (props) => {
    const headerEl = useRef(null);
    const togglerEl = useRef(null);
    const [navExpanded, setNavExpanded] = useState(false);

    const logo_url =
        props.location.pathname === "/" ||
        paseo_links.includes(props.location.pathname) ||
        ppc_links.includes(props.location.pathname)
            ? require("../../Images/blue-logo.png")
            : require("../../Images/Qase-Logo-White.svg");

    const navbarToggle = (isExpended) => {
        if (isExpended) {
            document.body.classList.add("main-nav--overlay-opened");
        } else {
            document.body.classList.remove("main-nav--overlay-opened");
        }
        setNavExpanded(isExpended);
    };

    useEffect(() => {
        if (navExpanded) {
            document.addEventListener("click", handleDocumentClick);
        }
        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, [navExpanded]);

    const handleDocumentClick = (e) => {
        //console.log(navExpanded);
        const container = headerEl.current;
        if (e.target !== container && !container.contains(e.target)) {
            togglerEl.current.click();
        }
    };

    return (
        <header
            className={classNames(
                "qase-navbar",
                props.location.pathname === "/" ||
                    paseo_links.includes(props.location.pathname) ||
                    ppc_links.includes(props.location.pathname)
                    ? "white-navbar"
                    : "blue-navbar"
            )}
            ref={headerEl}
        >
            <Navbar
                fixed="top"
                expand="md"
                onToggle={navbarToggle}
                collapseOnSelect
                expanded={navExpanded}
            >
                <div className="container">
                    <Navbar.Toggle
                        aria-controls="responsive-navbar-nav"
                        ref={togglerEl}
                    />
                    <Link className="navbar-brand" to="/">
                        <img src={logo_url} alt="Qase" />
                    </Link>
                    <Navbar.Collapse className="marketing-menu">
                        <Nav className="navbar-nav">
                            <NavDropdown title="About" renderMenuOnMount={true}>
                                <NavDropdown.Item
                                    href={`${
                                        props.location.pathname === "/"
                                            ? ""
                                            : "/"
                                    }#how-qase-works`}
                                >
                                    How it works
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    href={`${
                                        props.location.pathname === "/"
                                            ? ""
                                            : "/"
                                    }#why-qase`}
                                >
                                    Why Qase?
                                </NavDropdown.Item>
                            </NavDropdown>

                            <Nav.Item>
                                <Nav.Link
                                    eventKey="1"
                                    as={Link}
                                    to="/practice-areas"
                                >
                                    Practice Areas
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="2" as={Link} to="/lawyers">
                                    View Lawyers
                                </Nav.Link>
                            </Nav.Item>
                            {!ppc_links.includes(props.location.pathname) && (
                                <NavDropdown
                                    title="For Lawyers"
                                    renderMenuOnMount={true}
                                >
                                    <NavDropdown.Item
                                        as={Link}
                                        to="/lawyer-information"
                                    >
                                        Qase Leads
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        as={Link}
                                        to="/qase-convert"
                                    >
                                        Qase Convert
                                    </NavDropdown.Item>
                                </NavDropdown>
                            )}
                            <div className="nav-item">
                                <a
                                    className="nav-link"
                                    href="https://help.qase.ca/knowledge"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Help
                                </a>
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                    <div className="account-options">
                        <ul className="navbar-nav flex-row align-items-center">
                            <li className="nav-item">
                                <a
                                    className="nav-link d-none d-md-block"
                                    href={`${process.env.REACT_APP_SITE_URL}/user/login`}
                                >
                                    Login
                                </a>
                                <a
                                    className="nav-link d-md-none"
                                    href={`${process.env.REACT_APP_MOBILE_URL}/login`}
                                >
                                    Login
                                </a>
                            </li>
                            <li className="account-options-divider"></li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    href={`${process.env.REACT_APP_SITE_URL}/registration`}
                                >
                                    Register
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </Navbar>
        </header>
    );
};

export default withRouter(WhiteNavbar);
