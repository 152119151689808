import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Player, BigPlayButton } from "video-react";

import { BookConsultCard, FaqItem, Testimonials } from "../../Components";

import LegalEverything from "../../Images/legal_everything.webp";
import PaymentIcon from "../../Images/payment.webp";
import VideoIcon from "../../Images/video.webp";

import "./Home.scss";

const Home = () => {
    return (
        <Fragment>
            <section id="block-top">
                <div className="block-top-wrapper">
                    <div className="divider divider-bottom wave reverse">
                        <svg
                            width="102%"
                            clipRule="evenodd"
                            fillRule="evenodd"
                            strokeLinejoin="round"
                            strokeMiterlimit="2"
                            viewBox="0 0 4992 596"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="m-4.167 88.792c177.084-45.221 336.804-67.83 479.167-67.83 213.542 0 765.625 88.7 989.583 118.267 223.959 29.567 380.529 32.571 545.138 26.088 309.029-12.175 482.642-60.871 772.571-76.525 142.4-7.688 502.116 9.779 883.679 90.437 381.562 80.659 701.391 201.75 888.891 219.142 125 11.592 271.988 5.796 440.971-17.392v-389.312h-5000v97.125z"
                                fill="#fff"
                                className="mono"
                            />
                        </svg>
                    </div>

                    <div className="container-xl">
                        <div className="row">
                            <div className="col">
                                <div className="section-column">
                                    <BookConsultCard />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="partner-logos q-bg-midnightblue">
                    <div className="container-xl">
                        <p className="q-color-white text-center mb-2">
                            As featured on
                        </p>
                        <div className="row justify-content-center align-items-center">
                            <div className="partner-logo-item col-4 col-md-auto">
                                <img
                                    className="img-fluid law-logo"
                                    src={require("../../Images/PartnerLogos/law360@2x.png")}
                                    alt="Law 360"
                                />
                            </div>
                            <div className="partner-logo-item col-4 col-md-auto">
                                <img
                                    className="img-fluid straigth-logo"
                                    src={require("../../Images/PartnerLogos/straight@2x.png")}
                                    alt="Law 360"
                                />
                            </div>
                            <div className="partner-logo-item col-4 col-md-auto">
                                <img
                                    className="img-fluid bb-tech-logo"
                                    src={require("../../Images/PartnerLogos/bb-tech@2x.png")}
                                    alt="Law 360"
                                />
                            </div>
                            <div className="partner-logo-item col-3 col-md-auto">
                                <img
                                    className="img-fluid acba-logo"
                                    src={require("../../Images/PartnerLogos/acba@2x.png")}
                                    alt="Law 360"
                                />
                            </div>
                            <div className="partner-logo-item col-3 col-md-auto">
                                <img
                                    className="img-fluid b-vancouver-logo"
                                    src={require("../../Images/PartnerLogos/b-vancouver@2x.png")}
                                    alt="Law 360"
                                />
                            </div>
                            <div className="partner-logo-item col-3 col-md-auto">
                                <img
                                    className="img-fluid access-pro-bono-logo"
                                    src={require("../../Images/PartnerLogos/access-pro-bono@2x.png")}
                                    alt="Law 360"
                                />
                            </div>
                            <div className="partner-logo-item col-3 col-md-auto">
                                <img
                                    className="img-fluid globe-logo"
                                    src={require("../../Images/PartnerLogos/globe@2x.png")}
                                    alt="Law 360"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="why-qase">
                <div className="q-pb-64 q-pt-md-27 q-pb-md-55 q-bg-midnightblue q-color-white">
                    <div className="container-xl">
                        <div className="row no-gutters justify-content-center">
                            <div className="col-lg-4">
                                <h1 className="q-color-white text-center">
                                    Why Qase?
                                </h1>
                                <p className="p2 text-center mb-5">
                                    At Qase, we believe that finding the right
                                    lawyer should be quick, easy, and
                                    convenient.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="container-xl mb-5">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="q-card text-center">
                                    <img
                                        src={VideoIcon}
                                        alt="Connect Online"
                                        height="80"
                                        width="auto"
                                    />
                                    <h3 className="mb-3">Connect Online</h3>
                                    <p className="text-left">
                                        Talk face-to-face with your lawyer on
                                        any device, and communicate with your
                                        lawyer anytime & anywhere using instant
                                        messaging.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="q-card text-center">
                                    <img
                                        src={PaymentIcon}
                                        alt="No Suprise Bills"
                                        height="80"
                                        width="auto"
                                    />
                                    <h3 className="mb-3">No Surprise Bills</h3>
                                    <p className="text-left">
                                        Your lawyer will tell you upfront the
                                        estimated cost of providing you with
                                        legal services and ask for your
                                        permission each step of the way. You’ll
                                        never be billed for work you haven’t
                                        approved.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="q-card text-center">
                                    <img
                                        src={LegalEverything}
                                        alt="Everything in One Place"
                                        height="80"
                                        width="auto"
                                    />
                                    <h3 className="mb-3">
                                        Everything in One Place
                                    </h3>
                                    <p className="text-left">
                                        When you use Qase, you and your lawyer
                                        can easily schedule appointments, send
                                        messages, video chat, and share files
                                        quickly and securely.{" "}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-xl">
                        <div className="row justify-content-center">
                            <div className="col-md-4 text-center">
                                <a
                                    href={`${process.env.REACT_APP_SITE_URL}/create_case`}
                                    className="btn btn-melon"
                                >
                                    Book a Free Consult Now
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="how-qase-works">
                <div className="position-relative">
                    <div className="divider divider-top wave flip-x">
                        <svg
                            width="102%"
                            clipRule="evenodd"
                            fillRule="evenodd"
                            strokeLinejoin="round"
                            strokeMiterlimit="2"
                            viewBox="0 0 4992 596"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="m-4.167 88.792c177.084-45.221 336.804-67.83 479.167-67.83 213.542 0 765.625 88.7 989.583 118.267 223.959 29.567 380.529 32.571 545.138 26.088 309.029-12.175 482.642-60.871 772.571-76.525 142.4-7.688 502.116 9.779 883.679 90.437 381.562 80.659 701.391 201.75 888.891 219.142 125 11.592 271.988 5.796 440.971-17.392v-389.312h-5000v97.125z"
                                fill="#fff"
                                className="mono"
                            />
                        </svg>
                    </div>
                    <div className="divider divider-bottom wave-gray reverse">
                        <svg
                            width="102%"
                            clipRule="evenodd"
                            fillRule="evenodd"
                            strokeLinejoin="round"
                            strokeMiterlimit="2"
                            viewBox="0 0 4992 596"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="m-4.167 88.792c177.084-45.221 336.804-67.83 479.167-67.83 213.542 0 765.625 88.7 989.583 118.267 223.959 29.567 380.529 32.571 545.138 26.088 309.029-12.175 482.642-60.871 772.571-76.525 142.4-7.688 502.116 9.779 883.679 90.437 381.562 80.659 701.391 201.75 888.891 219.142 125 11.592 271.988 5.796 440.971-17.392v-389.312h-5000v97.125z"
                                fill="#fff"
                                className="mono"
                            ></path>
                        </svg>
                    </div>
                    <div className="q-pt-66 q-pb-98 q-pt-md-143 q-pb-md-173">
                        <div className="container-xl">
                            <h1 className="q-color-midnightblue text-center q-mb-md-50">
                                How Qase Works:
                            </h1>
                            <div className="row no-gutters justify-content-center">
                                <div className="col-md-10">
                                    <Player
                                        playsInline
                                        poster={require("../../Images/FindandWork.webp")}
                                        src={require("../../Videos/FindandWork.mp4")}
                                    >
                                        <BigPlayButton position="center" />
                                    </Player>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="what-is-saying">
                <div className="q-bg-whitesmoke q-py-40 q-pt-md-38 q-pb-md-72">
                    <div className="container-xl">
                        <h1 className="text-center q-color-midnightblue q-mb-32 q-mb-md-40">
                            What Our Community is Saying…
                        </h1>

                        <div className="row">
                            <div className="col-md-4">
                                <Testimonials
                                    text="I’m so grateful for your service, the lawyer who attended to me was amazing. He really took the time and explained the case and the probabilities."
                                    name="Employment Law Client"
                                />
                            </div>
                            <div className="col-md-4">
                                <Testimonials
                                    text="Clear concise and detailed answers. Excellent interaction."
                                    name="Small Business Client"
                                />
                            </div>
                            <div className="col-md-4">
                                <Testimonials
                                    text="My lawyer treated me very professionally and was very considerate."
                                    name="Family Law Client"
                                    is_revers={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="need-help">
                <div className="position-relative">
                    <div className="divider divider-top wave-gray flip-x">
                        <svg
                            width="102%"
                            clipRule="evenodd"
                            fillRule="evenodd"
                            strokeLinejoin="round"
                            strokeMiterlimit="2"
                            viewBox="0 0 4992 596"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="m-4.167 88.792c177.084-45.221 336.804-67.83 479.167-67.83 213.542 0 765.625 88.7 989.583 118.267 223.959 29.567 380.529 32.571 545.138 26.088 309.029-12.175 482.642-60.871 772.571-76.525 142.4-7.688 502.116 9.779 883.679 90.437 381.562 80.659 701.391 201.75 888.891 219.142 125 11.592 271.988 5.796 440.971-17.392v-389.312h-5000v97.125z"
                                fill="#fff"
                                className="mono"
                            ></path>
                        </svg>
                    </div>
                    <div className="divider divider-bottom wave-gray flip-y">
                        <svg
                            width="102%"
                            clipRule="evenodd"
                            fillRule="evenodd"
                            strokeLinejoin="round"
                            strokeMiterlimit="2"
                            viewBox="0 0 4992 596"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="m-4.167 88.792c177.084-45.221 336.804-67.83 479.167-67.83 213.542 0 765.625 88.7 989.583 118.267 223.959 29.567 380.529 32.571 545.138 26.088 309.029-12.175 482.642-60.871 772.571-76.525 142.4-7.688 502.116 9.779 883.679 90.437 381.562 80.659 701.391 201.75 888.891 219.142 125 11.592 271.988 5.796 440.971-17.392v-389.312h-5000v97.125z"
                                fill="#fff"
                                className="mono"
                            ></path>
                        </svg>
                    </div>

                    <div className="q-pt-57 q-pb-70 q-py-md-184">
                        <div className="container-xl text-center">
                            <h1 className="q-color-white">
                                Ready to book a free consult?
                            </h1>
                            <p className="p2 text-center mb-3">
                                Get started with a few simple steps
                            </p>

                            <div className="row justify-content-center">
                                <div className="col-md-4 text-center">
                                    <a
                                        href={`${process.env.REACT_APP_SITE_URL}/create_case`}
                                        className="btn btn-blue"
                                    >
                                        Book a Free Consult Now
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="faqs">
                <div className="q-bg-whitesmoke q-py-40 q-pt-md-55 q-pb-md-40">
                    <div className="container-xl">
                        <h1 className="q-color-midnightblue text-center q-mb-md-42 q-mb-32">
                            Frequently Asked Questions
                        </h1>

                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <div className="accordion-wrapper">
                                    <div className="q-mb-24 q-mb-md-32">
                                        <FaqItem
                                            title="How much does it cost to use Qase?"
                                            description="Signing up for an account and first consultations are always free. If you hire a lawyer, there are no retainer or up-front fees. Lawyer rates are transparent and you only pay for actual time worked on the case."
                                        />
                                    </div>

                                    <div className="q-mb-24 q-mb-md-32">
                                        <FaqItem
                                            title="How do I pay for my lawyer?"
                                            description="When you hire a lawyer at Qase, you must pay for their services using a credit card (all major credit cards are accepted). Pre-paid credit cards are also accepted, however cash, cheque and gift cards are not."
                                        />
                                    </div>

                                    <div className="q-mb-24 q-mb-md-32">
                                        <FaqItem
                                            title="What happens if I need to go to court?"
                                            description="If your legal issue requires you to go to court, Qase will connect you with a lawyer who has the experience to help you navigate the legal system, represent you in court and ensure your legal rights are always protected."
                                        />
                                    </div>

                                    <div className="q-mb-24 q-mb-md-32">
                                        <FaqItem
                                            title="Are Qase lawyers qualified?"
                                            description="At Qase, we put all lawyers through a rigorous 5 step screening process before they can use our service. You will have peace of mind knowing your lawyer is looking out for your best interests."
                                        />
                                    </div>

                                    <div className="q-mb-24 q-mb-md-32">
                                        <FaqItem
                                            title="What if I don't like my lawyer?"
                                            description="Qase will connect you with a lawyer that can help you determine what kind of legal help you need. If your lawyer doesn’t meet your expectations, we make it quick and easy to find an alternative lawyer to take your case."
                                        />
                                    </div>

                                    <div className="">
                                        <details className="accordion-item">
                                            <summary>
                                                <div className="accordion_item__accordion-title">
                                                    <h3 className="m-0">
                                                        Will Qase share my
                                                        information?
                                                    </h3>
                                                </div>
                                            </summary>
                                            <div className="accordion_item__accordion-body">
                                                <p>
                                                    Qase keeps your information
                                                    secure, private, encrypted
                                                    and stored in Canada. Only
                                                    your lawyer can access the
                                                    details of your case. We'll
                                                    never share your personal
                                                    information with other
                                                    parties. Read our{" "}
                                                    <Link to="/privacy_policy">
                                                        Privacy Policy.
                                                    </Link>
                                                </p>
                                            </div>
                                        </details>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default Home;
