import React from 'react'

const PPCHeading = ({ title, description, link, mobile_link }) => {
    return (
        <section className="header q-bg-whitesmoke position-relative">
            <div className="q-pt-45 q-pb-115 q-pb-xl-45 text-center">
                <div className="container">

                    <h1 className="heading-1 q-color-midnightblue">{title}</h1>
                    <h4 className="subheading-1 q-color-midnightblue">{description}</h4>
                    <h4 className="subheading-2 q-color-midnightblue">15 MINUTE FREE INITIAL CONSULTATION</h4>
                    <a className="btn btn-melon d-none d-md-inline-block desktop" href={link}>
                        Get Started Now with your <br />
                                15 minute free consultation
                        </a>
                    <a className="btn btn-melon d-md-none mobile" href={mobile_link}>
                        Get Started Now with your <br />
                                15 minute free consultation
                        </a>
                </div>
            </div>
            <div className="divider divider-bottom wave reverse" style={{ position: 'relative', maxHeight: 'none' }}>
                <svg width="102%" clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 4992 596" xmlns="http://www.w3.org/2000/svg">
                    <path d="m-4.167 88.792c177.084-45.221 336.804-67.83 479.167-67.83 213.542 0 765.625 88.7 989.583 118.267 223.959 29.567 380.529 32.571 545.138 26.088 309.029-12.175 482.642-60.871 772.571-76.525 142.4-7.688 502.116 9.779 883.679 90.437 381.562 80.659 701.391 201.75 888.891 219.142 125 11.592 271.988 5.796 440.971-17.392v-389.312h-5000v97.125z" fill="#fff" className="mono" />
                </svg>
            </div>
        </section>
    )
};

export default PPCHeading;