import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter } from "react-router-dom";
// import * as serviceWorker from "./serviceWorker";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";

import App from "../App";

import "./styles/index.scss";
//import * as serviceWorker from './serviceWorker';

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_TAG_MENEGER_ID,
};
TagManager.initialize(tagManagerArgs);

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);

ReactDOM.hydrate(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
