import React from 'react'

const PASeoHeading = ({ title, description, link, mobile_link, css_class }) => {
    return (
        <section className={`pa-header position-relative ${css_class}`}>

            <div className="q-pt-182 q-pt-md-192 header-wrapper">
                <div className="container">
                    <div className="text-center q-mb-56 text-white">
                        <h2 className="mb-3">{title}</h2>
                        <p className="p2 mb-0">{description}</p>
                    </div>

                    <div className="text-center">
                        <p className="p2 text-white text-center text-uppercase mb-4">15 MINUTE FREE INITIAL CONSULTATION</p>

                        <a className="btn btn-melon d-none d-md-inline-block" href={link}>
                            Book a Free Consult Now
                        </a>
                        <a className="btn btn-melon d-md-none" href={mobile_link}>
                            Book a Free Consult Now
                        </a>
                    </div>
                </div>
            </div>
            <div className="divider divider-bottom wave reverse" style={{ position: 'relative', maxHeight: 'none' }}>
                <svg width="102%" clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 4992 596" xmlns="http://www.w3.org/2000/svg">
                    <path d="m-4.167 88.792c177.084-45.221 336.804-67.83 479.167-67.83 213.542 0 765.625 88.7 989.583 118.267 223.959 29.567 380.529 32.571 545.138 26.088 309.029-12.175 482.642-60.871 772.571-76.525 142.4-7.688 502.116 9.779 883.679 90.437 381.562 80.659 701.391 201.75 888.891 219.142 125 11.592 271.988 5.796 440.971-17.392v-389.312h-5000v97.125z" fill="#fff" className="mono" />
                </svg>
            </div>
        </section>
    )
}

export default PASeoHeading
