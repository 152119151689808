import React from "react";
import { Link, withRouter } from "react-router-dom";
import Icon from "@mdi/react";
import {
    mdiMapMarkerOutline,
    mdiEmailOutline,
    // mdiInstagram,
    // mdiYoutube, mdiLinkedin, mdiTwitter, mdiFacebook
} from "@mdi/js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebookF,
    faInstagram,
    faYoutube,
    faLinkedinIn,
    faTwitter,
} from "@fortawesome/free-brands-svg-icons";

import { getPPCLinks } from "../../Utils/Helpers";

import "./Footer.scss";

const ppc_links = getPPCLinks();

const Footer = (props) => {
    return (
        <footer id="footer">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <div className="row">
                            <div className="col-12">
                                <div className="footer-logo">
                                    <img
                                        src={require("../../Images/Qase-Logo-White.svg")}
                                        alt="Qase"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row q-mb-38 q-mb-md-66">
                            <div className="col-md-6 col-lg-5 col-xl-4">
                                <div className="mb-3">
                                    <div className="media">
                                        <Icon
                                            path={mdiMapMarkerOutline}
                                            color="white"
                                            size={1}
                                            className="q-mr-8"
                                        />
                                        <div className="media-body">
                                            <p className="q-mb-15">
                                                701 - 33 Water Street
                                            </p>
                                            <p className="q-mb-15">
                                                Vancouver, BC V6B 1R4
                                            </p>
                                            <p className="q-mb-15">
                                                (844) 435-7658
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="">
                                    <div className="media">
                                        <Icon
                                            path={mdiEmailOutline}
                                            color="white"
                                            size={1}
                                            className="q-mr-8"
                                        />
                                        <div className="media-body">
                                            <span className="mb-0">
                                                info@qase.ca
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="social-button">
                                    <a
                                        href="https://www.instagram.com/qasecanada/"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <FontAwesomeIcon icon={faInstagram} />
                                        {/* <Icon path={mdiInstagram} color="white" /> */}
                                        {/* <i className="mdi mdi-instagram"></i> */}
                                    </a>
                                    <a
                                        href="https://www.youtube.com/channel/UCQm1BCHrTTD62pyeTFvY4HQ"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <FontAwesomeIcon icon={faYoutube} />
                                        {/* <Icon path={mdiYoutube} color="white" /> */}
                                        {/* <i className="mdi mdi-youtube"></i> */}
                                    </a>
                                    <a
                                        href="https://www.linkedin.com/company/qaseinc/"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <FontAwesomeIcon icon={faLinkedinIn} />
                                        {/* <Icon path={mdiLinkedin} color="white" /> */}
                                        {/* <i className="mdi mdi-linkedin"></i> */}
                                    </a>
                                    <a
                                        href="https://twitter.com/QaseCanada?lang=en"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <FontAwesomeIcon icon={faTwitter} />
                                        {/* <Icon path={mdiTwitter} color="white" /> */}
                                        {/* <i className="mdi mdi-twitter"></i> */}
                                    </a>
                                    <a
                                        href="https://www.facebook.com/qasecanada/"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        {/* <Icon path={mdiFacebook} color="white" /> */}
                                        {/* <i className="mdi mdi-facebook"></i> */}
                                        <FontAwesomeIcon icon={faFacebookF} />
                                    </a>
                                </div>
                            </div>
                            <div className="col-6 col-md-3 col-lg-2 col-xl-2">
                                {!ppc_links.includes(
                                    props.location.pathname,
                                ) && (
                                    <ul className="list-unstyled footer-menu">
                                        <li className="list-header">
                                            <p className="q-mb-15">Resources</p>
                                        </li>
                                        <li>
                                            <p className="q-mb-15">
                                                <Link to="/practice-areas">
                                                    Practice Areas
                                                </Link>
                                            </p>
                                        </li>
                                    </ul>
                                )}
                            </div>
                            <div className="col-6 col-md-3 col-lg-5 col-xl-6">
                                {!ppc_links.includes(
                                    props.location.pathname,
                                ) && (
                                    <ul className="list-unstyled footer-menu">
                                        <li>
                                            <Link to="/contact">Contact</Link>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6">
                                <p className="mb-2 mb-md-0">
                                    Proudly made in Vancouver, British Columbia,
                                    Canada
                                </p>
                            </div>
                            <div className="col-12 col-md-6">
                                <p className="text-md-right m-0">
                                    <Link to="/privacy_policy">Privacy</Link>
                                    &nbsp;|&nbsp;<Link to="/terms">Terms</Link>
                                    |&nbsp;
                                    <Link to="/qase-convert-terms">
                                        SaaS Terms
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default withRouter(Footer);
