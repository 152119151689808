import React, { Fragment } from 'react';

import { FaqItem, WhiteChartBlock, LawyerInformationBlock, BlueHero } from '../../Components';

import "./LawyerInformation.scss";


const LawyerInformation = () => {
    return (
        <Fragment>
            <BlueHero
                title="Modern law"
                descriptions={[
                    "Qase is an online service that helps people find and work with lawyers who can help them with their everyday legal problems.",
                    "We find leads. You connect with clients online."
                ]}
                image_src={require('../../Images/Qase_online_video_consult.png')}
                wave='wave-white'
            />

            <div id="page-lawyer-information">


                {/* Join Case */}
                <section id="join-case">
                    <div className="join-case-wrapper position-relative overflow-hidden">
                        <div className="q-py-65 q-py-md-80 q-py-100-lg">
                            <div className="divider divider-bottom wave flip-y">
                                <svg width="102%" clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 4992 596" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m-4.167 88.792c177.084-45.221 336.804-67.83 479.167-67.83 213.542 0 765.625 88.7 989.583 118.267 223.959 29.567 380.529 32.571 545.138 26.088 309.029-12.175 482.642-60.871 772.571-76.525 142.4-7.688 502.116 9.779 883.679 90.437 381.562 80.659 701.391 201.75 888.891 219.142 125 11.592 271.988 5.796 440.971-17.392v-389.312h-5000v97.125z" fill="#fff" className="mono"></path>
                                </svg>
                            </div>
                            <div className="container">
                                <div className="q-mb-1_3 overflow-hidden">
                                    <div className="q-mb-1_3">
                                        <div>
                                            <h2 className="q-color-midnightblue text-center">Join Qase and watch your business grow</h2>
                                        </div>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <LawyerInformationBlock
                                            title="Instant Payment"
                                            description="No funds in trust and credit card pre-authorization means you are paid instantly as you work."
                                            image_url={require("../../Images/ApprovedCard.svg")}
                                        />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <LawyerInformationBlock
                                            title="Try it risk free"
                                            description="There's no risk to try it: leads are free; you only pay a flat fee after providing billable services."
                                            image_url={require("../../Images/Onlineservices.svg")}
                                        />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <LawyerInformationBlock
                                            title="Flexibility"
                                            description="Practice where and when you want and be more-selective in the matters you chose. Meet the service expectations of today and tomorrow."
                                            image_url={require("../../Images/Flexibility_1.svg")}
                                        />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <LawyerInformationBlock
                                            title="Everything in one place"
                                            description="File sharing, messaging, appointment scheduling and video calling are just some of the many features accessible from the Qase platform."
                                            image_url={require("../../Images/OnePlace_1.svg")}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <section id="offer-legal">
                    <div className="offer-legal-wrapper position-relative q-bg-midnightblue">
                        <div className="divider divider-bottom wave-gray reverse">
                            <svg width="102%" clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 4992 596" xmlns="http://www.w3.org/2000/svg">
                                <path d="m-4.167 88.792c177.084-45.221 336.804-67.83 479.167-67.83 213.542 0 765.625 88.7 989.583 118.267 223.959 29.567 380.529 32.571 545.138 26.088 309.029-12.175 482.642-60.871 772.571-76.525 142.4-7.688 502.116 9.779 883.679 90.437 381.562 80.659 701.391 201.75 888.891 219.142 125 11.592 271.988 5.796 440.971-17.392v-389.312h-5000v97.125z" fill="#fff" className="mono"></path>
                            </svg>
                        </div>
                        <div className="q-py-45 q-py-md-60 q-py-75-lg">
                            <div className="container">
                                <div className="q-mb-1_3 text-center q-color-white">
                                    <h2 className="q-color-white">Offer your legal services the way clients want them</h2>
                                    <p className="q-mb-1_5">&nbsp;</p>
                                    <p className="mb-0">Clients want to:</p>
                                </div>

                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        <div className="qr-py-3 text-center">
                                            <div className="q-mb-20">
                                                <img src={require("../../Images/mobile_video_call.png")} alt="Meet" />
                                            </div>
                                            <div className="q-mb-20 q-color-white">
                                                <h3 className="q-color-white">Meet by Phone or Video</h3>
                                                <p className="mb-0">
                                                    Meet with a lawyer over the
                                            <br />
                                                    phone or through video calls
                                        </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4">
                                        <div className="qr-py-3 text-center">
                                            <div className="q-mb-20">
                                                <img src={require("../../Images/know_the_costs.png")} alt="Meet" />
                                            </div>
                                            <div className="q-mb-20 q-color-white">
                                                <h3 className="q-color-white">Know the Cost Upfront</h3>
                                                <p className="mb-0">
                                                    Know how much it will cost
                                            <br />
                                                    before they engage with a lawyer
                                        </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4">
                                        <div className="qr-py-3 text-center">
                                            <div className="q-mb-1_3">
                                                <img src={require("../../Images/pay_online.png")} alt="Meet" />
                                            </div>
                                            <div className="q-mb-1_3 q-color-white">
                                                <h3 className="q-color-white">Pay As-They-Go</h3>
                                                <p className="mb-0">
                                                    Work with lawyers online and
                                            <br />
                                                    pay by credit card
                                        </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="text-center qr-py-3">
                                    <div className="q-mb-1_3">
                                        <p className="qr-mb-1">
                                            <a className="btn btn-melon btn-lg" href="https://app.qase.ca/registration">Sign up for your free account</a>
                                        </p>

                                        <p className="mb-0 small q-color-white">to begin delivering legal services fully online</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section id="convenience-speed">
                    <div className="convenience-speed-wrapper q-bg-whitesmoke position-relative">
                        <div className="divider divider-bottom wave-white reverse">
                            <svg width="102%" clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 4992 596" xmlns="http://www.w3.org/2000/svg">
                                <path d="m-4.167 88.792c177.084-45.221 336.804-67.83 479.167-67.83 213.542 0 765.625 88.7 989.583 118.267 223.959 29.567 380.529 32.571 545.138 26.088 309.029-12.175 482.642-60.871 772.571-76.525 142.4-7.688 502.116 9.779 883.679 90.437 381.562 80.659 701.391 201.75 888.891 219.142 125 11.592 271.988 5.796 440.971-17.392v-389.312h-5000v97.125z" fill="#fff" className="mono"></path>
                            </svg>
                        </div>
                        <div className="q-py-65 q-py-md-80 q-py-100-lg">
                            <div className="container">
                                <div className="row justify-content-start align-content-center">
                                    <div className="col-12 col-md-12 col-lg-4 order-1 order-lg-2 q-mb-1_3 mb-lg-0">
                                        <div className="blue-block q-bg-midnightblue qr-p-3">
                                            <div className="q-mb-1_3">
                                                <div>
                                                    <p className="qr-my-3 qr-mb-md-4">We surveyed potential clients and found they are looking for:</p>
                                                    <h3 className="q-color-white q-mb-1_3 qr-mb-lg-4 q-px-md-3 q-px-lg-0 d-md-inline-block d-lg-block text-align-left">CONVENIENCE</h3>
                                                    <h3 className="q-color-white q-mb-1_3 qr-mb-lg-4 q-px-md-3 q-px-lg-0 d-md-inline-block d-lg-block text-align-left">SPEED</h3>
                                                    <h3 className="q-color-white mb-0 q-px-md-3 q-px-lg-0 d-md-inline-block d-lg-block text-align-left">COST CERTAINTY</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 order-2 order-lg-1">
                                        <div className="q-mb-1_3">
                                            <WhiteChartBlock
                                                description="Indicated they are more likely to meet with a <strong>lawyer online</strong>"
                                                image_url={require("../../Images/75.svg")}
                                            />
                                        </div>

                                        <div className="q-mb-1_3">
                                            <WhiteChartBlock
                                                description="Said they would like to meet by <strong>phone or video</strong> chat"
                                                image_url={require("../../Images/60.svg")}
                                            />
                                        </div>


                                        <WhiteChartBlock
                                            description="Want to know how <strong>much it will cost</strong> before they engage with a lawyer"
                                            image_url={require("../../Images/83.svg")}
                                        />

                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 order-3 order-lg-3">
                                        <div className="q-mb-1_3">
                                            <WhiteChartBlock
                                                description="Prefer to <strong>pay-as-you-do</strong> instead of an upfront retainer"
                                                image_url={require("../../Images/82.svg")}
                                            />
                                        </div>

                                        <div className="q-mb-1_3">
                                            <WhiteChartBlock
                                                description="Prefer to pay with a <strong>credit card</strong>"
                                                image_url={require("../../Images/66.svg")}
                                            />
                                        </div>


                                        <WhiteChartBlock
                                            description="Want their lawyers to be <strong>available outside business hours</strong>"
                                            image_url={require("../../Images/53.svg")}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="q-py-25 q-py-md-40 q-py-55-lg">
                        <div className="container">
                            <div className="qr-py-3">
                                <div className="q-mb-1_3 text-center">
                                    <h2 className="q-color-midnightblue">Interested, what’s next?</h2>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-md-6 q-brd-right q-brd-darkslategray">
                                    <div className="qr-p-3 qr-p-md-5">
                                        <div className="q-mb-1_3 text-center">
                                            <p className="q-mb-1_5 lead">Get started offering legal services online now!</p>
                                            <div className="text-center">
                                                <a className="btn btn-melon btn-lg" href={`${process.env.REACT_APP_SITE_URL}/registration`}>Register Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="qr-p-3 qr-p-md-5">
                                        <div className="q-mb-1_3 text-center">
                                            <p className="q-mb-1_5 lead">Want more information or to set up a demo of the platform?</p>
                                            <div className="text-center">
                                                <a className="btn btn-melon btn-lg" href="/contact">Contact Us</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="l-faqs">
                    <div className="l-faqs-wrapper q-bg-whitesmoke position-relative overflow-hidden">
                        <div className="divider divider-top wave-white">
                            <svg width="102%" clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 4992 596" xmlns="http://www.w3.org/2000/svg">
                                <path d="m-4.167 88.792c177.084-45.221 336.804-67.83 479.167-67.83 213.542 0 765.625 88.7 989.583 118.267 223.959 29.567 380.529 32.571 545.138 26.088 309.029-12.175 482.642-60.871 772.571-76.525 142.4-7.688 502.116 9.779 883.679 90.437 381.562 80.659 701.391 201.75 888.891 219.142 125 11.592 271.988 5.796 440.971-17.392v-389.312h-5000v97.125z" fill="#fff" className="mono"></path>
                            </svg>
                        </div>

                        <div className="q-py-45 q-py-md-60 q-py-75-lg">
                            <div className="container">

                                <h2 className="q-color-darkslategray text-center q-mb-md-42 q-mb-32">Frequently Asked Questions</h2>

                                <div className="row justify-content-center">
                                    <div className="col-md-10">
                                        <div className="accordion-wrapper">
                                            <div className="q-mb-24 q-mb-md-32">
                                                <FaqItem
                                                    title="What is Qase and how does it work?"
                                                    description="Qase is an online service that helps people find and work with lawyers who can help them with their everyday legal problems. Clients pay as they go via credit card for each activity performed by the lawyer immediately upon completion."
                                                />
                                            </div>

                                            <div className="q-mb-24 q-mb-md-32">
                                                <FaqItem
                                                    title="How do I connect with new clients by using Qase?"
                                                    description="We bring new clients to you via the Qase marketing engine. Clients provide intake information and characterize the type of legal need they have. New clients can request a 15 minute consultation."
                                                />
                                            </div>

                                            <div className="q-mb-24 q-mb-md-32">
                                                <FaqItem
                                                    title="How can my practice benefit from using Qase?"
                                                    description="The Qase platform provides the online tools to streamline your practice. We’ve automated invoicing and payment processes and made it easier for clients to start working with you. You get to focus on the law and providing legal services to your clients."
                                                />
                                            </div>

                                            <div className="q-mb-24 q-mb-md-32">
                                                <FaqItem
                                                    title="How much do I pay to use Qase?"
                                                    description="There is no sign up or monthly subscription fee to use Qase. When you use the Qase platform for billable services, you’ll pay a flat fee. There is no charge to use the platform for non-billable and pro-bono work. Contact us to find out more."
                                                />
                                            </div>

                                            <div className="">
                                                <FaqItem
                                                    title="How do I get started?"
                                                    description="Register your account now to get started offering legal services online. For more information or to set up a demo of the platform, send us an email. We look forward to hearing from you!"
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </section>

            </div>
        </Fragment >
    )
}

export default LawyerInformation;
