import React from 'react'

import "./FaqItem.scss";

const FaqItem = ({ title, description }) => {
    return (
        <details className="accordion-item">
            <summary>
                <div className="accordion_item__accordion-title">
                    <h3 className="m-0">{title}</h3>
                </div>

            </summary>
            <div className="accordion_item__accordion-body">
                <p dangerouslySetInnerHTML={{ __html: description }}></p>
            </div>
        </details>
    )
}

export default FaqItem
