import React from "react";
import "./BlueHero.scss";

export default function BlueHero({
    title,
    descriptions,
    image_src,
    wave,
    type = false,
    subtitle = "",
}) {
    return (
        <section className="blue-hero">
            {(() => {
                switch (type) {
                    case "area-detail":
                        return (
                            <div className={`blue-hero-${type}`}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            {title && (
                                                <h1 className="q-color-white">
                                                    {title}
                                                </h1>
                                            )}
                                        </div>
                                        <div className="col-12">
                                            <div className="text-center qr-py-3">
                                                <a
                                                    className="btn btn-white d-none d-md-inline-block q-px-40"
                                                    href={`${process.env.REACT_APP_SITE_URL}?newcase=true`}
                                                >
                                                    Book a Free Consult
                                                    <svg
                                                        className="svg-inline--fa fa-calendar fa-w-14 q-ml-10"
                                                        aria-hidden="true"
                                                        focusable="false"
                                                        data-prefix="far"
                                                        data-icon="calendar"
                                                        role="img"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 448 512"
                                                        data-fa-i2svg=""
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M400 64h-48V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H160V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zm-6 400H54c-3.3 0-6-2.7-6-6V160h352v298c0 3.3-2.7 6-6 6z"
                                                        ></path>
                                                    </svg>
                                                </a>
                                                <a
                                                    className="btn btn-white d-md-none q-px-40"
                                                    href={`${process.env.REACT_APP_MOBILE_URL}/case/create`}
                                                >
                                                    Book a Free Consult
                                                    <svg
                                                        className="svg-inline--fa fa-calendar fa-w-14 q-ml-10"
                                                        aria-hidden="true"
                                                        focusable="false"
                                                        data-prefix="far"
                                                        data-icon="calendar"
                                                        role="img"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 448 512"
                                                        data-fa-i2svg=""
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M400 64h-48V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H160V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zm-6 400H54c-3.3 0-6-2.7-6-6V160h352v298c0 3.3-2.7 6-6 6z"
                                                        ></path>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    case "empty":
                        return (
                            <div className="blue-hero-wrapper position-relative d-none d-md-block">
                                <div
                                    className={`divider divider-bottom reverse ${
                                        wave ? wave : "wave-gray"
                                    }`}
                                >
                                    <svg
                                        width="102%"
                                        clipRule="evenodd"
                                        fillRule="evenodd"
                                        strokeLinejoin="round"
                                        strokeMiterlimit="2"
                                        viewBox="0 0 4992 596"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="m-4.167 88.792c177.084-45.221 336.804-67.83 479.167-67.83 213.542 0 765.625 88.7 989.583 118.267 223.959 29.567 380.529 32.571 545.138 26.088 309.029-12.175 482.642-60.871 772.571-76.525 142.4-7.688 502.116 9.779 883.679 90.437 381.562 80.659 701.391 201.75 888.891 219.142 125 11.592 271.988 5.796 440.971-17.392v-389.312h-5000v97.125z"
                                            fill="#fff"
                                            className="mono"
                                        ></path>
                                    </svg>
                                </div>
                                <div className="container">
                                    <div style={{ minHeight: "100px" }}></div>
                                </div>
                            </div>
                        );
                    case "policy":
                        return (
                            <div className="blue-hero-wrapper position-relative">
                                <div
                                    className={`divider divider-bottom reverse ${
                                        wave ? wave : "wave-gray"
                                    }`}
                                >
                                    <svg
                                        width="102%"
                                        clipRule="evenodd"
                                        fillRule="evenodd"
                                        strokeLinejoin="round"
                                        strokeMiterlimit="2"
                                        viewBox="0 0 4992 596"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="m-4.167 88.792c177.084-45.221 336.804-67.83 479.167-67.83 213.542 0 765.625 88.7 989.583 118.267 223.959 29.567 380.529 32.571 545.138 26.088 309.029-12.175 482.642-60.871 772.571-76.525 142.4-7.688 502.116 9.779 883.679 90.437 381.562 80.659 701.391 201.75 888.891 219.142 125 11.592 271.988 5.796 440.971-17.392v-389.312h-5000v97.125z"
                                            fill="#fff"
                                            className="mono"
                                        ></path>
                                    </svg>
                                </div>
                                <div className="q-py-25 q-py-md-40 q-py-55-lg">
                                    <div className="container">
                                        <div className="row justify-content-between">
                                            <div className="col-12">
                                                <div className=".qr-pb-3 qr-pb-md-5">
                                                    <div className="q-mb-1_3 text-center">
                                                        {title && (
                                                            <h1 className="q-color-white">
                                                                {title}
                                                            </h1>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    default:
                        return (
                            <div className="blue-hero-wrapper position-relative">
                                <div
                                    className={`divider divider-bottom reverse ${
                                        wave ? wave : "wave-gray"
                                    }`}
                                >
                                    <svg
                                        width="102%"
                                        clipRule="evenodd"
                                        fillRule="evenodd"
                                        strokeLinejoin="round"
                                        strokeMiterlimit="2"
                                        viewBox="0 0 4992 596"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="m-4.167 88.792c177.084-45.221 336.804-67.83 479.167-67.83 213.542 0 765.625 88.7 989.583 118.267 223.959 29.567 380.529 32.571 545.138 26.088 309.029-12.175 482.642-60.871 772.571-76.525 142.4-7.688 502.116 9.779 883.679 90.437 381.562 80.659 701.391 201.75 888.891 219.142 125 11.592 271.988 5.796 440.971-17.392v-389.312h-5000v97.125z"
                                            fill="#fff"
                                            className="mono"
                                        ></path>
                                    </svg>
                                </div>
                                <div className="q-py-25 q-py-md-40 q-py-55-lg">
                                    <div className="container">
                                        <div className="row justify-content-between">
                                            <div className="col-12 col-md-8">
                                                <div className="q-mb-1_3">
                                                    {title && (
                                                        <h1 className="q-color-white">
                                                            {title}
                                                        </h1>
                                                    )}
                                                    {subtitle && (
                                                        <h3
                                                            className="q-color-white"
                                                            style={{
                                                                marginBottom:
                                                                    "50px",
                                                            }}
                                                        >
                                                            {subtitle}
                                                        </h3>
                                                    )}
                                                    {descriptions &&
                                                        descriptions.map(
                                                            (
                                                                description,
                                                                index
                                                            ) => (
                                                                <p
                                                                    key={index}
                                                                    className="text-white"
                                                                >
                                                                    {
                                                                        description
                                                                    }
                                                                </p>
                                                            )
                                                        )}
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-lg-3 col-xl-3 d-none d-md-block">
                                                <div className="q-mb-1_3">
                                                    {image_src && (
                                                        <img
                                                            className="img-fluid"
                                                            src={image_src}
                                                            alt="#"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                }
            })()}
        </section>
    );
}
