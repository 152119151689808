import React from "react";
import classnames from "classnames";

import "./GetStartedBlock.scss";

const GetStartedBlock = ({ link, mobile_link, is_flip = true }) => {
    return (
        <section id="get-started" className="q-bg-midnightblue overflow-hidden">
            <div className="get-started-wrapper position-relative">
                <div
                    className={classnames("divider divider-top wave-gray", {
                        "flip-x": is_flip,
                    })}
                >
                    <svg
                        width="102%"
                        clipRule="evenodd"
                        fillRule="evenodd"
                        strokeLinejoin="round"
                        strokeMiterlimit="2"
                        viewBox="0 0 4992 596"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="m-4.167 88.792c177.084-45.221 336.804-67.83 479.167-67.83 213.542 0 765.625 88.7 989.583 118.267 223.959 29.567 380.529 32.571 545.138 26.088 309.029-12.175 482.642-60.871 772.571-76.525 142.4-7.688 502.116 9.779 883.679 90.437 381.562 80.659 701.391 201.75 888.891 219.142 125 11.592 271.988 5.796 440.971-17.392v-389.312h-5000v97.125z"
                            fill="#fff"
                            className="mono"
                        ></path>
                    </svg>
                </div>
                <div className="q-py-25 q-py-md-40 q-py-55-lg">
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12 col-lg-8 qr-pt-4">
                                <div className="q-mb-1_3 q-color-white text-center">
                                    <h2 className="q-color-white">
                                        Get Started with Qase
                                    </h2>
                                    <p className="mb-0">
                                        Ready to talk to a lawyer now? You are
                                        just a few clicks away from an
                                        experienced lawyer who can help. Request
                                        your FREE legal consult!
                                    </p>
                                </div>
                                <div className="q-mb-1_3">
                                    <div className="d-flex">
                                        <div className="mx-auto">
                                            <div className="form-inline">
                                                <div className="form-group">
                                                    <a
                                                        href={
                                                            !!link
                                                                ? link
                                                                : `${process.env.REACT_APP_SITE_URL}/create_case`
                                                        }
                                                        className="btn btn-melon d-none d-md-block desktop"
                                                    >
                                                        Book a Free Consult Now
                                                    </a>
                                                    <a
                                                        href={
                                                            !!mobile_link
                                                                ? mobile_link
                                                                : `${process.env.REACT_APP_SITE_URL}/create_case`
                                                        }
                                                        className="btn btn-melon d-md-none mobile"
                                                    >
                                                        Book a Free Consult Now
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GetStartedBlock;
