import React from 'react';
import { Player, BigPlayButton } from 'video-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { PPCHeading, Testimonials } from '../../Components';
import { getPPCPagesHeaderTexts } from '../../Utils/Helpers';

import "./PPCLandingPage.scss";

const PPCLandingPage = (props) => {

    let headerInfo = getPPCPagesHeaderTexts(props.location.pathname);

    return (
        <div id="ppc-landing">
            <PPCHeading {...headerInfo} />

            <section className="how-it-works">
                <div className="q-pb-45 q-pt-25 q-bg-midnightblue">
                    <div className="container">

                        <div className="partner-logos">
                            <p className="q-color-white text-center mb-2">As featured on</p>
                            <div className="row justify-content-center align-items-center">
                                <div className="partner-logo-item col-4 col-md-auto">
                                    <img className="img-fluid law-logo" src={require("../../Images/PartnerLogos/law360@2x.png")} alt="Law 360" />
                                </div>
                                <div className="partner-logo-item col-4 col-md-auto">
                                    <img className="img-fluid straigth-logo" src={require("../../Images/PartnerLogos/straight@2x.png")} alt="Law 360" />
                                </div>
                                <div className="partner-logo-item col-4 col-md-auto">
                                    <img className="img-fluid bb-tech-logo" src={require("../../Images/PartnerLogos/bb-tech@2x.png")} alt="Law 360" />
                                </div>
                                <div className="partner-logo-item col-3 col-md-auto">
                                    <img className="img-fluid acba-logo" src={require("../../Images/PartnerLogos/acba@2x.png")} alt="Law 360" />
                                </div>
                                <div className="partner-logo-item col-3 col-md-auto">
                                    <img className="img-fluid b-vancouver-logo" src={require("../../Images/PartnerLogos/b-vancouver@2x.png")} alt="Law 360" />
                                </div>
                                <div className="partner-logo-item col-3 col-md-auto">
                                    <img className="img-fluid access-pro-bono-logo" src={require("../../Images/PartnerLogos/access-pro-bono@2x.png")} alt="Law 360" />
                                </div>
                                <div className="partner-logo-item col-3 col-md-auto">
                                    <img className="img-fluid globe-logo" src={require("../../Images/PartnerLogos/globe@2x.png")} alt="Law 360" />
                                </div>
                            </div>
                        </div>

                        <h1 className="heading-1 text-center q-mb-1_3">How it Works</h1>
                        <div className="q-mb-1_3">
                            <div className="row">
                                <div className="col-md-6 align-self-center order-md-2">
                                    <div className="q-mb-1_3">
                                        <Player
                                            playsInline
                                            poster={require("../../Images/PPC/video-bg.jpg")}
                                            src={require("../../Videos/VideoDemoV1.mov")}
                                        >
                                            <BigPlayButton position="center" />
                                        </Player>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <ul className="list-unstyled">
                                        <li className="media">
                                            <div className="circled-wrapper q-mr-15">
                                                <FontAwesomeIcon icon={faCheck} color="#ffffff" size="lg" />
                                            </div>
                                            <div className="media-body">
                                                <h4 className="mt-0 mb-1 subheading-3">Step 1: Book Free Consult</h4>
                                                <p className="q-color-white">
                                                    Use our online calendar tool <br />
                                                to select a lawyer who can <br />
                                                assist you with your legal <br />
                                                matter.
                                            </p>
                                            </div>
                                        </li>
                                        <li className="media">
                                            <div className="circled-wrapper q-mr-15">
                                                <FontAwesomeIcon icon={faCheck} color="#ffffff" size="lg" />
                                            </div>
                                            <div className="media-body">
                                                <h4 className="mt-0 mb-1 subheading-3">Step 2: Meet by Phone or Video</h4>
                                                <p className="q-color-white">
                                                    Arrange to meet with a <br />
                                                lawyer by either phone or <br />
                                                video chat. Connect entirely <br />
                                                online!
                                            </p>
                                            </div>
                                        </li>
                                        <li className="media">
                                            <div className="circled-wrapper q-mr-15">
                                                <FontAwesomeIcon icon={faCheck} color="#ffffff" size="lg" />
                                            </div>
                                            <div className="media-body">
                                                <h4 className="mt-0 mb-1 subheading-3">Step 3: Pay-As-You-Go</h4>
                                                <p className="q-color-white">
                                                    Work with your lawyer <br />
                                                online and pay by credit <br />
                                                card through our secured <br />
                                                online payment platform.
                                            </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <a className="btn btn-melon d-none d-md-inline-block desktop" href={headerInfo.link}>
                                Get Started Now with your <br />
                                15 minute free consultation
                                </a>
                            <a className="btn btn-melon d-md-none mobile" href={headerInfo.mobile_link}>
                                Get Started Now with your <br />
                                15 minute free consultation
                            </a>

                        </div>
                    </div>
                </div>
            </section>

            <section className="why-qase">
                <div className="q-py-45">
                    <div className="container">
                        <div className="q-mb-1_3">
                            <h1 className="heading-1 q-color-midnightblue text-center">Why Qase?</h1>
                            <h4 className="subheading-3 q-color-midnightblue text-center">Here are some of the reasons people choose to use Qase for all their legal needs</h4>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm">
                                <div className="q-mb-1_3 text-center">
                                    <img src={require("../../Images/PPC/Icon-Laptop.png")} className="img-fluid q-width-75" alt="Book a Free Consult" />
                                </div>
                                <div className="q-mb-1_3 text-center">
                                    <p className="mb-0 q-color-midnightblue">Choose a lawyer to meet with at a time that works for you</p>
                                </div>
                            </div>
                            <div className="col-12 col-sm">
                                <div className="q-mb-1_3 text-center">
                                    <img src={require("../../Images/PPC/Icon-Docu.png")} className="img-fluid q-width-75" alt="Book a Free Consult" />
                                </div>
                                <div className="q-mb-1_3 text-center">
                                    <p className="mb-0 q-color-midnightblue">Learn about your legal options and how a lawyer can help</p>
                                </div>
                            </div>
                            <div className="col-12 col-sm">
                                <div className="q-mb-1_3 text-center">
                                    <img src={require("../../Images/PPC/Icon-Secure.png")} className="img-fluid q-width-75" alt="Book a Free Consult" />
                                </div>
                                <div className="q-mb-1_3 text-center">
                                    <p className="mb-0 q-color-midnightblue">Work with your lawyer online and pay by credit card</p>
                                </div>
                            </div>
                            <div className="col-12 col-sm">
                                <div className="q-mb-1_3 text-center">
                                    <img src={require("../../Images/PPC/Icon-Calendar.png")} className="img-fluid q-width-75" alt="Book a Free Consult" />
                                </div>
                                <div className="q-mb-1_3 text-center">
                                    <p className="mb-0 q-color-midnightblue">Learn about your legal options and how a lawyer can help</p>
                                </div>
                            </div>
                            <div className="col-12 col-sm">
                                <div className="q-mb-1_3 text-center">
                                    <img src={require("../../Images/PPC/Icon-Money.png")} className="img-fluid q-width-75" alt="Book a Free Consult" />
                                </div>
                                <div className="q-mb-1_3 text-center">
                                    <p className="mb-0 q-color-midnightblue">Work with your lawyer online and pay by credit card</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="testimonilas">
                <div className="q-py-45 q-bg-whitesmoke">
                    <div className="container">
                        <div className="q-mb-1_3">
                            <h1 className="heading-1 q-color-midnightblue text-center">Testimonials</h1>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <Testimonials
                                    text="I'm so  thankfull for your service, the 
                                            lawyer who attendedto me was 
                                            amazing. He really took the time and 
                                            explained the case and the 
                                            probabilities."
                                    name="- Qase Client"
                                />
                            </div>
                            <div className="col-md-4">
                                <Testimonials
                                    text=" Never has engaging with clients
                                            remotely and through technology
                                            looked so good!"
                                    name="- Qase Lawyer"
                                />
                            </div>
                            <div className="col-md-4">
                                <Testimonials
                                    text="The lawyer was super helpful and I
                                            appreciated the information and action
                                            plan he helped me develop."
                                    name="- Qase Client"
                                    is_revers={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="need-help">
                <div className="q-py-45">
                    <div className="container-fluid text-center">
                        <div className="">
                            <h2 className="q-color-white">Get Started With Qase Today</h2>
                            <p className="mb-4">Ready to talk to a lawyer?</p>

                            <p className="mb-0">
                                <a className="btn btn-blue d-none d-md-inline-block desktop" href={headerInfo.link}>
                                    BOOK YOUR 15 MINUTE FREE Consultation NOW
                                </a>
                                <a className="btn btn-blue d-md-none mobile" href={headerInfo.mobile_link}>
                                    BOOK YOUR 15 MINUTE FREE Consultation NOW
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div >
    );
};

export default PPCLandingPage;
