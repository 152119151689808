import React from "react";
import { Link } from "react-router-dom";

import "./LawyerBlock.scss";

const LawyerBlock = ({
    full_name,
    areas,
    profile_picture,
    link_url,
    practice_start,
}) => {
    let image_url = profile_picture
        ? profile_picture
        : require("../../Images/Qase-Default-Avatar.png");
    return (
        <Link to={link_url ? link_url : "/"} className="card lawyer-block">
            <div className="card-body">
                <div className="media mb-3">
                    <div className="lawyer-picture">
                        <img
                            src={image_url}
                            alt="Lawyer"
                            className="rounded-circle"
                        />
                    </div>
                    <div className="media-body">
                        {full_name && (
                            <h3 className="q-color-darkslategray">
                                {full_name}
                            </h3>
                        )}
                        {practice_start && (
                            <div className="lawyer-exp">
                                {practice_start} of experience
                            </div>
                        )}
                    </div>
                </div>
                {areas && (
                    <div className="lawyer-practice-areas mb-3">
                        {areas.map((area, index) => {
                            if (index < 3) {
                                return (
                                    <div
                                        key={area.item_id}
                                        className="badge lawyer-badge"
                                    >
                                        {area.name}
                                    </div>
                                );
                            }
                            return null;
                        })}
                        {areas.length > 3 && (
                            <div className="badge lawyer-badge">
                                + {areas.length - 3}
                            </div>
                        )}
                    </div>
                )}

                {/* <Link to="/" className="btn btn-melon btn-block">Read More</Link> */}
            </div>
        </Link>
    );
};

export default LawyerBlock;
