import React from "react";
import { ReactComponent as Group4 } from "../../Images/Group4.svg";
import { ReactComponent as Group3 } from "../../Images/Group3.svg";
import { ReactComponent as Group2 } from "../../Images/Group2.svg";

import "./BookConsultCard.scss";

const BookConsultCard = ({ maxWidth, link, mobile_link, freeConsultTime }) => {
    return (
        <div className="book-consult-wrapper">
            <h2 className="text-center">Need legal help?</h2>
            <p className="p2 text-center">
                Book a FREE online consult with a lawyer in British Columbia
            </p>
            <div className="mb-4 text-center">
                <a
                    className="btn btn-melon d-none d-md-block desktop"
                    href={
                        !!link
                            ? link
                            : `${process.env.REACT_APP_SITE_URL}/create_case`
                    }
                >
                    Book a Free Consult Now
                </a>
                <a
                    href={
                        !!mobile_link
                            ? mobile_link
                            : `${process.env.REACT_APP_SITE_URL}/create_case`
                    }
                    className="btn btn-melon d-md-none mobile"
                >
                    Book a Free Consult Now
                </a>
            </div>
            <div className="d-flex d-md-block">
                <ul className="list-group list-inline mx-auto">
                    <li className="list-group-item d-flex align-items-center">
                        <Group4 />
                        <span>
                            {!!freeConsultTime ? freeConsultTime : 15} min FREE
                            consult
                        </span>
                    </li>
                    <li className="list-group-item d-flex align-items-center">
                        <Group3 />
                        <span>All devices: phone, tablet, laptop</span>
                    </li>
                    <li className="list-group-item d-flex align-items-center">
                        <Group2 />
                        <span>Anywhere in British Columbia</span>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default BookConsultCard;
