import React, { Fragment } from 'react'
import { BookConsultCard, GetStartedBlock } from '../../Components'

const BFC_URL = `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_NVBC_AREA}&practice_area_p=${process.env.REACT_APP_NVBC_AREA_PARENT}&is_single=true&is_promo=true`;
//const BFC_MOBILE_URL = `${process.env.REACT_APP_MOBILE_URL}/case/create?practice_area=${process.env.REACT_APP_NVBC_AREA}&practice_area_p=${process.env.REACT_APP_NVBC_AREA_PARENT}&is_single=true&is_promo=true`;

const NVBC = () => {
    return (
        <Fragment>
            <section className="blue-hero">
                <div className="position-relative">
                    <div className="q-py-25 q-py-md-40 q-py-55-lg">
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col-12 col-md-8">
                                    <div className="q-mb-1_3 text-white" >
                                        <h1>
                                            New Ventures BC Tech Start-up Legal Help
                                        </h1>
                                        <p>Welcome NVBC.</p>
                                        <p>Book a free consult to talk to a lawyer about your legal issues</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <BookConsultCard link={BFC_URL} mobile_link={BFC_URL} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="divider divider-bottom reverse wave-gray" style={{ position: "relative" }}>
                        <svg width="102%" clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 4992 596" xmlns="http://www.w3.org/2000/svg">
                            <path d="m-4.167 88.792c177.084-45.221 336.804-67.83 479.167-67.83 213.542 0 765.625 88.7 989.583 118.267 223.959 29.567 380.529 32.571 545.138 26.088 309.029-12.175 482.642-60.871 772.571-76.525 142.4-7.688 502.116 9.779 883.679 90.437 381.562 80.659 701.391 201.75 888.891 219.142 125 11.592 271.988 5.796 440.971-17.392v-389.312h-5000v97.125z" fill="#fff" className="mono"></path>
                        </svg>
                    </div>
                </div>
            </section>

            <section id="why-qase">
                <div className="q-pb-64 q-pt-md-27 q-pb-md-55 q-bg-whitesmoke q-color-midnightblue">
                    <div className="container-xl">
                        <div className="row no-gutters justify-content-center">
                            <div className="col-lg-4">
                                <h1 className="text-center">Why Qase?</h1>
                                <p className="p2 text-center mb-5">
                                    At Qase, we believe that finding the right lawyer should be quick, easy, and convenient.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="container-xl mb-5">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="q-card text-center">
                                    <img src={require("../../Images/video.png")} alt="Connect Online" />
                                    <h3 className="mb-3">Connect Online</h3>
                                    <p className="text-left">Talk face-to-face with your lawyer on any device, and communicate with your lawyer anytime & anywhere using instant messaging.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="q-card text-center">
                                    <img src={require("../../Images/payment.png")} alt="No Suprise Bills" />
                                    <h3 className="mb-3">No Surprise Bills</h3>
                                    <p className="text-left">Your lawyer will tell you upfront the estimated cost of providing you with legal services and ask for your permission each step of the way. You’ll never be billed for work you haven’t approved.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="q-card text-center">
                                    <img src={require("../../Images/legal_everything.png")} alt="Everything in One Place" />
                                    <h3 className="mb-3">Everything in One Place</h3>
                                    <p className="text-left">When you use Qase, you and your lawyer can easily schedule appointments, send messages, video chat, and share files quickly and securely. </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <GetStartedBlock link={BFC_URL} mobile_link={BFC_URL} />
        </Fragment>
    )
}

export default NVBC
