import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faAngleLeft } from '@fortawesome/free-solid-svg-icons'

import { axios } from '../../Core';
import { GetStartedBlock, BlueHero, LawyerBlock } from '../../Components'
import { Link } from 'react-router-dom';
import { getPracticeAreaLink } from '../../Utils/Helpers';



const PracticeLawyers = (props) => {

    const [isLoading, setLoading] = useState(true);
    const [lawyers, setLawyers] = useState([]);
    const [practiceArea, setPracticeArea] = useState([]);

    const fetchPracticeLawyers = async (id) => {
        let name = id.split("_").join(" ");
        let formData = new FormData();
        formData.append("area_name", name);
        const response = await axios.post('/api/v1/marketing/lawyers_by_practice', formData);
        if (response.data.success) {
            console.log(response.data);
            setLawyers(response.data.data.lawyers);
            setPracticeArea(response.data.data.practice_area);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPracticeLawyers(props.match.params.id)
    }, [props.match.params.id]);

    return (
        <div className="q-bg-whitesmoke">
            <BlueHero
                title={isLoading ? "" : `${!Array.isArray(practiceArea) ? practiceArea.name :
                    ""} Lawyers`}
                image_src={require('../../Images/find_lawyer_0.png')}
            />

            <div className="container">
                <div className="qr-pb-md-3">
                    <p className="mb-0">
                        <Link to={isLoading ? "" : !Array.isArray(practiceArea) ? getPracticeAreaLink(practiceArea) : ""}>
                            <FontAwesomeIcon icon={faAngleLeft} color="#220c89" />
                            &nbsp;Back to {isLoading ? "" : !Array.isArray(practiceArea) ? `${practiceArea.name}` : ""}
                        </Link>
                    </p>
                </div>
            </div>

            <div className="qr-py-3 qr-py-md-4 qr-py-lg-5">
                <div className="container">
                    {isLoading ?
                        <div className="col-12 p-5 text-center">
                            <FontAwesomeIcon icon={faSpinner} pulse size="6x" color="#220c89" />
                        </div>
                        :
                        <div className="row">
                            {
                                lawyers.map(lawyer => {
                                    return (
                                        <div key={lawyer.uuid} className="col-sm-6 col-md-6 col-lg-4 qr-my-3">
                                            <LawyerBlock
                                                full_name={`${lawyer.first_name} ${lawyer.last_name}`}
                                                areas={lawyer.practice_areas}
                                                profile_picture={lawyer.profile_picture}
                                                link_url={`/${practiceArea.name.trim().toLowerCase().split(' ').join('_')}/lawyer/${lawyer.uuid}`}
                                                practice_start={lawyer.practice_start_date}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }

                </div>
            </div>

            <GetStartedBlock />
        </div>
    )
}

export default PracticeLawyers;
