export default PPCLinks => {
    return ["/family_law_ppc", "/child_custody_ppc", "/separation_divorce_ppc", "/child_support_ppc", "/spousal_support_ppc",
        "/high_net-worth_divorce_ppc", "/property_division_ppc", "/marriage_agreements_ppc", "/separation_agreements_ppc", "/collaborative_family_law_ppc",
        "/emergency_enforcement_orders_ppc", "/family_lawyer_ppc", "/family_law_attorney_ppc", "/divorce_lawyer_ppc", "/divorce_attorney_ppc",
        "/adoptions_ppc", "/alternative_dispute_resolution_ppc", "/cohabitation_agreements_ppc", "/matrimonial_home_ppc", "/prenup_cohab_agreements_ppc",
        "/wills_estates_ppc", "/wills_powers_attorney_ppc", "/succession_planning_ppc", "/capacity_claims_ppc", "/dependents_relief_ppc", "/passing_accounts_ppc",
        "/removal_executor_ppc", "/trust_disputes_ppc", "/estates_trusts_ppc", "/guardianships_ppc", "/advising_fiduciaries_ppc", "/capacity_proceedings_ppc",
        "/representations_persons_disability_ppc", "/probate_applications_ppc", "/challenges_executors_ppc", "/estate_litigation_ppc", "/employment_benefits_ppc",
        "/employment_contracts_ppc", "/severance_package_ppc", "/wrongful_dismissal_ppc", "/harassment_discrimination_workplace_ppc", "/labour_lawyers_ppc",
        "/employment_lawyers_ppc", "/constructive_dismissal_ppc", "/termination_without_cause_ppc", "/termination_for_cause_ppc", "/ministry_labour_complaints_ppc",
        "/duty_to_accommodate_ppc", "/employment_insurance_ppc", "/temporary_layoffs_ppc", "/workplace_investigation_conflict_ppc", "/employment_standards_compliance_ppc",
        "/performance_mgmt_discipline_ppc", "/privacy_workplace_ppc", "/employee_termination_ppc", "/wrongful_dismissal_discrimination_ppc"]
}