import React from "react";

import { BlueHero, GetStartedBlock } from "../../Components";

import "./Terms.scss";

const Terms = () => {
	return (
		<div id="terms-page">
			<BlueHero type="policy" title="Qase Terms & Conditions" />
			<section>
				<div className="q-py-25 q-py-40-md q-py-55-lg q-bg-whitesmoke">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-12 col-md-10 col-lg-9 col-xl-8">
								<div className="q-mb-1_3">
									<p>
										<em>Last Updated: May 16th, 2019</em>
									</p>

									<p>
										Below are the terms
										of&nbsp;service&nbsp;governing your use
										and access
										of&nbsp;the&nbsp;Qase&nbsp;Platform.&nbsp;Your
										use
										of&nbsp;the&nbsp;Qase&nbsp;Platform,&nbsp;your&nbsp;use
										of the&nbsp;information&nbsp;and
										products which may be
										accessed&nbsp;through the&nbsp;Qase
										Platform, and your Qase Account
										are&nbsp;subject to the terms and
										conditions&nbsp;set out
										below,&nbsp;which may
										be&nbsp;amended&nbsp;by Qase&nbsp;from
										time to time (hereafter “
										<strong>these</strong>&nbsp;
										<strong>Terms</strong>”). Your use
										of&nbsp;the Qase
										Platform&nbsp;constitutes&nbsp;your&nbsp;acceptance
										of these&nbsp;Terms.&nbsp;&nbsp;
									</p>

									<p>
										<strong>
											If you do not agree with
											these&nbsp;Terms,&nbsp;do&nbsp;not
											use&nbsp;the Qase Platform
										</strong>
										.
									</p>

									<hr />
									<p>&nbsp;</p>

									<p>
										<strong>1. Purpose</strong>
									</p>

									<p>
										You agree that you will use the Qase
										Platform and your Qase Account&nbsp;in
										good faith. You will only use the Qase
										Platform to seek the provision
										of&nbsp;legal services&nbsp;through the
										Platform.&nbsp;If you enter into Terms
										of Engagement with a Participating
										Lawyer, you commit to working with that
										Participating Lawyer solely through the
										Platform.
									</p>

									<hr />
									<p>&nbsp;</p>

									<p>
										<strong>2. Definitions</strong>
									</p>

									<p>
										When used in these
										Terms,&nbsp;the&nbsp;words and phrases
										listed below shall have the meanings
										given them:&nbsp;
									</p>

									<p>
										“<strong>Client Information</strong>
										”&nbsp;means&nbsp;any content or
										information that you upload or submit
										to, store on,&nbsp;or
										send&nbsp;via,&nbsp;the Qase Platform,
										or any communications, documents or
										other information sent to you by a
										Participating Lawyer in connection with
										any legal services provided to
										you;&nbsp;
									</p>

									<p>
										“<strong>Notice</strong>
										”&nbsp;means&nbsp;notification of a
										change or an amendment to these
										Terms;&nbsp;
									</p>

									<p>
										“<strong>Participating</strong>&nbsp;
										<strong>Lawyer</strong>
										”&nbsp;means&nbsp;a lawyer&nbsp;paying
										for access and use&nbsp;of&nbsp;the Qase
										Platform;&nbsp;
									</p>

									<p>
										“<strong>Policies</strong>
										”&nbsp;means&nbsp;all policies,
										standards and procedures that may be
										issued by&nbsp;Qase&nbsp;from time to
										time with respect to the
										use&nbsp;of&nbsp;the Qase
										Platform;&nbsp;
									</p>

									<p>
										“<strong>Privacy</strong>&nbsp;
										<strong>Policy</strong>”&nbsp;has the
										meaning given in section&nbsp;4&nbsp;of
										these terms;&nbsp;
									</p>

									<p>
										“<strong>Qase</strong>&nbsp;
										<strong>Account</strong>”&nbsp;means a
										User account, which is linked to a
										unique personalized login ID and
										password, that is needed to access and
										use the Qase Platform and to pay for the
										legal services obtained through the Qase
										Platform;&nbsp;
									</p>

									<p>
										“<strong>Qase</strong>&nbsp;
										<strong>Platform</strong>”&nbsp;or the
										“Platform”&nbsp;means the Qase online
										platform,
										any&nbsp;information&nbsp;and&nbsp;products&nbsp;available
										on the Qase Platform,&nbsp;and&nbsp;the
										processes and tools for engaging and
										obtaining the services of a
										Participating Lawyer on the Qase
										Platform;&nbsp;
									</p>

									<p>
										“<strong>Terms</strong>&nbsp;
										<strong>of</strong>&nbsp;
										<strong>Engagement</strong>
										”&nbsp;means&nbsp;an&nbsp;agreement&nbsp;for
										legal service&nbsp;delivery through the
										Qase Platform&nbsp;you enter&nbsp;with a
										Participating Lawyer; and&nbsp;
									</p>

									<p>
										“<strong>User</strong>” means any
										individual who has established a Qase
										Account&nbsp;or who otherwise uses the
										Qase Platform&nbsp;to receive legal
										services.
									</p>

									<hr />
									<p>&nbsp;</p>

									<p>
										<strong>
											3. Security of Login ID and Password
										</strong>
									</p>

									<p>
										To&nbsp;establish and manage your Qase
										Account,&nbsp;Qase&nbsp;may
										issue&nbsp;you&nbsp;a
										login&nbsp;ID&nbsp;and password. You
										will use your login ID and password
										to&nbsp;access&nbsp;the Qase
										Platform.&nbsp;You agree&nbsp;it is your
										responsibility
										to&nbsp;keep&nbsp;your&nbsp;login&nbsp;ID&nbsp;and
										password confidential.&nbsp;&nbsp;&nbsp;
									</p>

									<p>
										Your use of the Qase
										Platform&nbsp;constitutes your
										acceptance&nbsp;of&nbsp;all policies,
										standards and procedures that&nbsp;Qase
										may implement&nbsp;from time to
										time&nbsp;(“Policies”).&nbsp;Qase’s&nbsp;current
										Policies may be
										found&nbsp;in&nbsp;Schedule
										A&nbsp;of&nbsp;this
										agreement.&nbsp;&nbsp;
									</p>

									<p>
										Qase&nbsp;reserves the right to
										deactivate any login&nbsp;ID&nbsp;and
										password&nbsp;in the following
										circumstances (each a “Deactivation
										Event”):&nbsp;
									</p>

									<ul>
										<li>
											where&nbsp;you violate any of the
											Policies;&nbsp;&nbsp;
										</li>
										<li>
											where&nbsp;you violate any Terms of
											Engagement with a Participating
											Lawyer;&nbsp;&nbsp;
										</li>
										<li>
											where you refuse to accept any
											modification of these Terms&nbsp;or
											the Policies; and&nbsp;
										</li>
										<li>
											where Qase believes it appropriate
											or necessary to do so.
										</li>
									</ul>

									<hr />
									<p>&nbsp;</p>

									<p>
										<strong>4. Privacy</strong>
									</p>

									<p>
										Qase&nbsp;recognizes&nbsp;the&nbsp;information
										you&nbsp;disclose&nbsp;to&nbsp;Qase
										or&nbsp;to&nbsp;a Participating Lawyer
										is sensitive in nature. Qase takes its
										privacy obligations
										seriously.&nbsp;&nbsp;
									</p>

									<p>
										Qase&nbsp;will not take any action that
										infringes on lawyer-client
										confidentiality that&nbsp;may
										exist&nbsp;between you and a
										Participating Lawyer.&nbsp;Having said
										that, when you or a Participating Lawyer
										you have engaged asks Qase for technical
										support, we may require access to parts
										of the Platform containing information
										to which we would not normally have
										access. Access to personal, sensitive or
										confidential information in this case is
										incidental to our technical
										support.&nbsp;
									</p>

									<p>
										For such occurrences and in respect of
										all information on the
										Platform,&nbsp;Qase has implemented a
										Privacy Policy that governs Qase’s
										collection, use and disclosure of
										information, including personal
										information.&nbsp;Qase’s&nbsp;Privacy
										Policy may be found&nbsp;on our
										website:&nbsp;
										<a
											href="http://www.qase.ca/"
											rel="noreferrer noopener"
											target="_blank"
										>
											www.qase.ca
										</a>
										.
									</p>

									<hr />
									<p>&nbsp;</p>

									<p>
										<strong>
											5. Availability of Platform
										</strong>
									</p>

									<p>
										You may access the Platform at anytime
										subject to Qase’s right to suspend
										operation of the Platform or any aspect
										of it, for any period, as needed.
										Reasons for suspending operation may
										include, but are not limited to,
										performance upgrades or maintenance.
										Qase will make reasonable efforts to
										notify you of any disruption in
										service.&nbsp;&nbsp;
									</p>

									<p>
										Please note,&nbsp;the Qase
										Platform&nbsp;and the information,
										products and
										services&nbsp;accessed&nbsp;through the
										Qase Platform&nbsp;may be modified,
										deleted, edited or withdrawn
										by&nbsp;Qase&nbsp;at its discretion and
										at any time without prior notice.
									</p>

									<hr />
									<p>&nbsp;</p>

									<p>
										<strong>
											6. Technical Support and Business
											Hours
										</strong>
									</p>

									<p>
										Should you require technical
										support,&nbsp;navigate to&nbsp;
										<u>
											<a
												href="http://www.qase.ca/"
												rel="noreferrer noopener"
												target="_blank"
											>
												www.qase.ca
											</a>
										</u>
										&nbsp;or email us at&nbsp;
										<u>
											<a href="mailto:support@qase.ca">
												support@qase.ca
											</a>
										</u>
										.&nbsp;
									</p>

									<p>
										Qase’s regular hours of business
										are&nbsp;8:30am to 4:30pm. (Pacific
										Standard Time), Mondays to Fridays,
										except statutory and some optional
										holidays in the Province of British
										Columbia.&nbsp;
									</p>

									<hr />
									<p>&nbsp;</p>

									<p>
										<strong>7. No Legal Advice</strong>
									</p>

									<p>
										The information, products and services
										on&nbsp;the Qase Platform&nbsp;are
										provided with the understanding that at
										no time does&nbsp;Qase&nbsp;review
										documents for legal sufficiency, draw
										legal conclusions, provide legal advice
										or apply the law to the facts of
										your&nbsp;situation, and its services
										are not a substitute for the advice of a
										lawyer. The provision of information,
										products and services on and
										through&nbsp;the Qase Platform&nbsp;is
										not intended to create, and the receipt
										of any information, products and
										services does not constitute,
										a&nbsp;solicitor-client
										relationship&nbsp;between you
										and&nbsp;Qase.&nbsp;&nbsp;
									</p>

									<p>
										Please be advised, communications
										between you and&nbsp;Qase&nbsp;are
										protected by our Privacy&nbsp;Policy
										but&nbsp;are&nbsp;not&nbsp;protected
										by&nbsp;lawyer-client
										confidentiality.&nbsp;&nbsp;
									</p>

									<p>
										For more information about lawyer-client
										confidentiality, please refer to your
										Terms of Engagement&nbsp;with a
										Participating Lawyer.&nbsp;
									</p>

									<hr />
									<p>&nbsp;</p>

									<p>
										<strong>
											8. Accuracy of Information and
											Instructions
										</strong>
									</p>

									<p>
										It is your responsibility to provide
										accurate and complete&nbsp;personal and
										contact&nbsp;information and
										instructions to&nbsp;Qase&nbsp;and any
										Participating Lawyer you engage
										using&nbsp;the Qase Platform, and to
										update such information as necessary
										from time to time.
									</p>

									<hr />
									<p>&nbsp;</p>

									<p>
										<strong>
											9. Dissatisfaction with Platform
										</strong>
									</p>

									<p>
										If you are dissatisfied&nbsp;the Qase
										Platform&nbsp;or any services available
										on the&nbsp;Platform (including
										the&nbsp;ability to seek and obtain
										legal services),&nbsp;Qase&nbsp;may, but
										will not be obligated to,&nbsp;take
										commercially reasonable steps to correct
										the work,&nbsp;or in the case
										of&nbsp;legal services,&nbsp;connect you
										with&nbsp;another&nbsp;Participating
										Lawyer.
									</p>

									<hr />
									<p>&nbsp;</p>

									<p>
										<strong>10. Legal Services</strong>
									</p>

									<p>
										Qase&nbsp;takes reasonable
										measures&nbsp;to&nbsp;screen
										Participating Lawyers&nbsp;to:&nbsp;
									</p>

									<ul>
										<li>
											Determine&nbsp;they are a lawyer
											and&nbsp;confirm&nbsp;in&nbsp;which&nbsp;jurisdictions&nbsp;they
											are&nbsp;eligible to&nbsp;practice
											law;&nbsp;
										</li>
										<li>
											Check if the lawyer has been
											disciplined&nbsp;by&nbsp;the&nbsp;applicable
											governing legal body&nbsp;and review
											any disciplinary action
											that&nbsp;has been taken, if
											any;&nbsp;
										</li>
										<li>
											Search for records of unauthorized
											practice;&nbsp;
										</li>
										<li>
											Assess whether&nbsp;lawyers&nbsp;can
											reasonably&nbsp;demonstrate&nbsp;sufficient&nbsp;experience&nbsp;in
											the areas they claim to practice
											in&nbsp;by requesting and
											reviewing&nbsp;their detailed
											resume; and&nbsp;
										</li>
										<li>
											Assess a lawyer’s&nbsp;general
											ability to communicate effectively
											and clearly, as well as the
											lawyer’s&nbsp;customer
											focus,&nbsp;through an interview
											process&nbsp;and the review of any
											publicly available information on
											the lawyer.&nbsp;
										</li>
									</ul>

									<p>
										This information allows Qase to
										determine a lawyer’s suitability to
										practice over the
										Qase&nbsp;Platform.&nbsp; By declining a
										lawyer we make no judgement about their
										competency as a lawyer.&nbsp;
									</p>

									<p>
										In using the Qase Platform, you
										acknowledge that Qase&nbsp;has&nbsp;no
										liability in respect of any disputes or
										claims you may have in respect of any
										legal services provided to you by a
										Participating Lawyer. For greater
										certainty, any disputes with a
										Participating Lawyer will be handled
										directly between you and the
										Participating Lawyer and will be
										governed by&nbsp;the&nbsp;Terms of
										Engagement&nbsp;in place between you
										and&nbsp;the Participating Lawyer.&nbsp;
									</p>

									<hr />
									<p>&nbsp;</p>

									<p>
										<strong>
											11. Links to Third Party Sites
										</strong>
									</p>

									<p>
										The Qase Platform&nbsp;contains
										hyperlinks to&nbsp;platforms&nbsp;or
										websites&nbsp;operated by parties other
										than&nbsp;Qase.&nbsp;These&nbsp;hyperlinks
										are provided for your reference
										only.&nbsp;Qase&nbsp;does not
										control&nbsp;these&nbsp;platforms&nbsp;or
										websites&nbsp;and is not responsible for
										their contents.&nbsp;Qase’s inclusion
										of, reference or link to, any
										third-party&nbsp;information, material
										or content&nbsp;is not an endorsement,
										whether express or implied.&nbsp;Your
										access and use of&nbsp;any such
										third-party&nbsp;sites, including
										information, material, products and
										services therein, are solely at your own
										risk. Furthermore, our Privacy Policy is
										applicable only when you are on&nbsp;the
										Qase Platform. Once linked to
										another&nbsp;platform&nbsp;or website,
										you should read that site’s privacy
										policy before disclosing any personal
										information.
									</p>

									<hr />
									<p>&nbsp;</p>

									<p>
										<strong>
											12. Intellectual Property Rights
										</strong>
									</p>

									<p>
										Qase&nbsp;either owns the intellectual
										property rights in the underlying HTML,
										text, audio clips, video clips and other
										content on&nbsp;the Qase
										Platform&nbsp;(including the
										organization and layout of this site)
										or&nbsp;has obtained permission to use
										the content on&nbsp;the Qase Platform.
										No reproduction, distribution, or
										transmission of the copyrighted
										materials on this site is permitted
										without the prior written permission
										of&nbsp;Qase.&nbsp;
									</p>

									<p>
										Nothing in these Terms grants you any
										right of ownership in:&nbsp;the Qase
										Platform, any aspect of the Qase
										Platform, any content found on the Qase
										Platform, or&nbsp;any&nbsp;intellectual
										property rights&nbsp;associated with
										those things.
										Qase&nbsp;hereby&nbsp;grants&nbsp;you&nbsp;a&nbsp;limited,&nbsp;non-exclusive,&nbsp;revocable
										license to use and access the Qase
										Platform&nbsp;only so you may
										obtain&nbsp;legal services&nbsp;from a
										Participating Lawyer using the Platform,
										all of which is&nbsp;subject to these
										Terms and Qase’s discretion.&nbsp;
									</p>

									<hr />
									<p>&nbsp;</p>

									<p>
										<strong>
											13. Use of Third Parties
										</strong>
									</p>

									<p>
										Qase&nbsp;may, at its sole discretion,
										use third parties to complete work or
										services.&nbsp;Qase&nbsp;will use
										reasonable care in the selection of
										third parties and providing instructions
										to third parties.&nbsp;Qase&nbsp;cannot
										guarantee the performance of third
										parties or the timeliness of performance
										by third parties including, without
										limitation, courier or delivery
										services.&nbsp;
									</p>

									<hr />
									<p>&nbsp;</p>

									<p>
										<strong>14. Disclaimer</strong>
									</p>

									<p>
										Your use of&nbsp;the Qase
										Platform&nbsp;and any content downloaded
										from it is at your own risk.&nbsp;The
										Qase Platform&nbsp;and all information,
										products and services are provided on an
										“as is, as available” basis without
										representation or warranty of any kind,
										whether express or implied, including
										but not limited to implied warranties of
										merchantability, fitness
										for&nbsp;any&nbsp;purpose, title and
										non-infringement, all of which are
										disclaimed by&nbsp;Qase&nbsp;to the
										fullest extent permitted by law.&nbsp;
									</p>

									<p>
										Qase&nbsp;does not represent or
										warrant:&nbsp;&nbsp;
									</p>

									<ul>
										<li>
											the quality, accuracy or
											completeness of any claims,
											statements or information
											on&nbsp;the Platform;&nbsp;&nbsp;
										</li>
										<li>
											the quality, accuracy or
											completeness of&nbsp;the products
											and services&nbsp;you
											obtain&nbsp;through&nbsp;the Qase
											Platform;&nbsp;&nbsp;
										</li>
										<li>
											the suitability of any of the
											information, products and services
											contained on&nbsp;the Qase
											Platform&nbsp;or obtained
											through&nbsp;the Qase
											Platform&nbsp;for any
											purpose;&nbsp;&nbsp;
										</li>
										<li>
											that&nbsp;the Qase Platform&nbsp;or
											the information, products and
											services on or to be obtained
											through&nbsp;the Qase
											Platform&nbsp;will&nbsp;result
											in&nbsp;desired&nbsp;outcomes&nbsp;or&nbsp;meet
											your requirements or expectations;
											and&nbsp;&nbsp;
										</li>
										<li>
											that&nbsp;the Qase Platform&nbsp;or
											the information, products and
											services on or to be obtained
											through&nbsp;the Qase
											Platform&nbsp;will be available on
											an uninterrupted, timely, secure or
											error-free basis.
										</li>
									</ul>

									<hr />
									<p>&nbsp;</p>

									<p>
										<strong>
											15. Limitation on Liability
										</strong>
									</p>

									<p>
										In no event&nbsp;will&nbsp;Qase&nbsp;be
										liable for any damages whatsoever,
										whether direct or indirect, punitive,
										special, incidental, or consequential
										damages (including, without limitation,
										for any loss of business, revenue,
										profits, goodwill, data, documents,
										property, materials or equipment, or
										other economic advantage or intangible
										loss), arising out of or in connection
										with:
									</p>

									<ul>
										<li>
											the use of, or delay or inability to
											use,&nbsp;the Qase Platform&nbsp;or
											any information, products or
											services obtained through&nbsp;the
											Qase Platform;&nbsp;&nbsp;
										</li>
										<li>
											unauthorized access to, alteration
											of, or inaccuracy, error or
											omissions in your content,
											transmissions or data;&nbsp;&nbsp;
										</li>
										<li>
											the loss, alteration, destruction,
											damage&nbsp;corruption
											or&nbsp;recovery of any Client
											Information stored on&nbsp;the Qase
											Platform;&nbsp;
										</li>
										<li>
											service outages that are caused
											by&nbsp;Qase’s maintenance on the
											technology that underlies&nbsp;the
											Qase Platform;&nbsp;&nbsp;
										</li>
										<li>
											problems inherent in the use of the
											internet and electronic
											communications;&nbsp;&nbsp;
										</li>
										<li>
											failures of&nbsp;Qase’s service
											providers (including
											telecommunications, hosting, and
											power providers);&nbsp;&nbsp;
										</li>
										<li>computer viruses;&nbsp;&nbsp;</li>
										<li>
											natural disasters or other
											destruction or damage of&nbsp;Qase’s
											facilities, acts of nature, war,
											civil disturbance;&nbsp;
										</li>
										<li>
											any representations and warranties
											made to a User by a Participating
											Lawyer;&nbsp;
										</li>
										<li>
											any acts or omissions of a
											Participating Lawyer; and&nbsp;
										</li>
										<li>
											statements or conduct of any third
											party or otherwise arising out of
											the use of&nbsp;the Qase Platform,
											whether based in contract, tort
											(including negligence), or
											otherwise, even if there is a
											fundamental breach or
											if&nbsp;Qase&nbsp;has been advised
											of the possibility of damages.
										</li>
									</ul>

									<p>
										Without in any way limiting the
										generality of the foregoing, Qase is not
										liable for the acts or omissions of any
										third party acting on instructions from
										Qase.
									</p>

									<hr />
									<p>&nbsp;</p>

									<p>
										<strong>16. Dispute Resolution</strong>
									</p>

									<ul>
										<li>
											<strong>Jury Trial Waiver</strong>
											.&nbsp;You and Qase acknowledge and
											agree that we are each waiving the
											right to a trial by jury as to all
											arbitrable Disputes.&nbsp;
										</li>
										<li>
											<strong>
												No Class Actions or
												Representative Proceedings
											</strong>
											.&nbsp;You
											and&nbsp;Qase&nbsp;acknowledge and
											agree that we are each waiving the
											right to participate as a plaintiff
											or class member in any purported
											class action lawsuit.&nbsp;&nbsp;
										</li>
										<li>
											<strong>
												Exclusive Dispute Resolution
												Mechanism
											</strong>
											.&nbsp;The parties will resolve any
											dispute, controversy, disagreement
											or claim arising out of, relating to
											or in connection with this
											Agreement, or the breach,
											termination, existence or invalidity
											hereof (each, a “Dispute”), under
											the provisions of this section
											16&nbsp;which will be the exclusive
											mechanism for resolving any Dispute
											that may arise from time to
											time.&nbsp;
										</li>
										<li>
											<strong>Negotiations</strong>
											.&nbsp;The parties will first
											attempt in good faith to resolve any
											Dispute by negotiation and
											consultation between
											themselves.&nbsp;&nbsp;
										</li>
										<li>
											<strong>
												Litigation or Arbitration as a
												Final Resort
											</strong>
											.&nbsp;If the Parties cannot
											resolve&nbsp;the Dispute&nbsp;any
											Dispute within sixty (60) days after
											the&nbsp;first request to
											negotiate,&nbsp;either Party may
											file suit in a court of competent
											jurisdiction in accordance with the
											provisions of
											section&nbsp;19.&nbsp;Should a party
											desire to arbitrate a Dispute, the
											following rules apply:&nbsp;
										</li>
									</ul>

									<ol>
										<li>
											There shall
											be&nbsp;3&nbsp;arbitrators&nbsp;on
											the arbitrational panel. Each party
											shall nominate one arbitrator and
											the two arbitrators shall, among
											them, select the third
											arbitrator.&nbsp;
										</li>
										<li>
											The language of the arbitration
											shall be&nbsp;English.&nbsp;
										</li>
										<li>
											An oral hearing need not be
											held.&nbsp;&nbsp;
										</li>
										<li>
											There will be no appeal from the
											decision of the&nbsp;arbitration
											panel&nbsp;on questions of fact,
											law,&nbsp;or mixed fact and
											law.&nbsp;
										</li>
									</ol>

									<hr />
									<p>&nbsp;</p>

									<p>
										<strong>17. Waiver</strong>
									</p>

									<p>
										Any failure by&nbsp;Qase&nbsp;to insist
										upon or enforce strict performance of
										any provision of
										these&nbsp;Terms&nbsp;will&nbsp;not be
										construed as a waiver of any provision
										or rights contained in
										these&nbsp;Terms.&nbsp;
									</p>

									<hr />
									<p>&nbsp;</p>

									<p>
										<strong>18. Severability</strong>
									</p>

									<p>
										If any part of these&nbsp;Terms is
										determined to be unlawful, void, invalid
										or unenforceable, then that
										part&nbsp;will&nbsp;nonetheless be
										enforced to the fullest extent permitted
										by applicable law, and such
										determination&nbsp;will&nbsp;not affect
										the validity and enforceability of any
										other part of these&nbsp;Terms.
									</p>

									<hr />
									<p>&nbsp;</p>

									<p>
										<strong>19. Governing Law</strong>
									</p>

									<p>
										These&nbsp;Terms&nbsp;and the resolution
										of any dispute related&nbsp;in any
										way&nbsp;to these&nbsp;Terms,
										your&nbsp;access&nbsp;to or use
										of&nbsp;the Qase Platform,&nbsp;and the
										information, products,&nbsp;and services
										obtained through&nbsp;the Qase
										Platform&nbsp;will&nbsp;be governed by
										and construed in accordance with the
										laws of the Province of British Columbia
										and the federal laws of Canada
										applicable therein (without giving
										effect to any principles of conflicts of
										law).&nbsp;Subject to the opportunity to
										arbitrate set out in section 16, you
										consent and submit to the exclusive
										jurisdiction of the courts located in
										the Province of British Columbia in all
										questions and disputes arising out of
										your use of&nbsp;the Qase
										Platform&nbsp;and&nbsp;these&nbsp;Terms.&nbsp;
									</p>

									<hr />
									<p>&nbsp;</p>

									<p>
										<strong>20. Legal Notices</strong>
									</p>

									<p>
										Communications made&nbsp;by you
										to&nbsp;Qase&nbsp;through&nbsp;the&nbsp;Platform’s
										e-mail and messaging
										system&nbsp;are&nbsp;for convenience
										only and&nbsp;will&nbsp;not be deemed to
										constitute legal notice
										to&nbsp;Qase&nbsp;or&nbsp;any of its
										officers, agents, employees,&nbsp;or
										representatives&nbsp;for any purpose
										(including without limitation&nbsp;any
										existing or potential
										claim&nbsp;or&nbsp;cause of
										action).&nbsp;
									</p>

									<hr />
									<p>&nbsp;</p>

									<p>
										<strong>
											21. Changes to Terms of Use
										</strong>
									</p>

									<p>
										Qase&nbsp;may make changes to
										these&nbsp;Terms&nbsp;at any
										time,&nbsp;for any reason. Changes will
										take effect&nbsp;30 days&nbsp;after Qase
										posts&nbsp;a Notice on its website or
										otherwise communicates a&nbsp;Notice to
										you using the Platform. You may accept
										or decline Qase’s changes. Should you
										decline the changes, Qase will
										deactivate your login&nbsp;ID&nbsp;and
										password.&nbsp;
									</p>

									<p>
										Changes&nbsp;to
										these&nbsp;Terms&nbsp;will not apply
										retroactively and will become effective
										no sooner than&nbsp;30&nbsp;days
										after&nbsp;we post Notice. However,
										changes addressing new functions or
										services to&nbsp;the Qase
										Platform&nbsp;that are made for legal
										reasons will&nbsp;take immediate effect,
										with or without Notice. 
									</p>

									<hr />
									<p>&nbsp;</p>

									<p>
										<strong>
											22. Termination and Deactivaction of
											Account
										</strong>
									</p>

									<p>
										If you&nbsp;(a)&nbsp;choose to cease
										using&nbsp;the Qase
										Platform,&nbsp;(b)&nbsp;we
										discontinue&nbsp;services
										provided&nbsp;through&nbsp;the Qase
										Platform; or (c) your
										login&nbsp;ID&nbsp;and password are
										deactivated&nbsp;because of&nbsp;a
										Deactivation Event,&nbsp;we will, where
										possible,&nbsp;and subject to all other
										terms herein,&nbsp;give you
										reasonable&nbsp;notice&nbsp;and a chance
										to&nbsp;download a copy of&nbsp;your
										Client Information&nbsp;from&nbsp;the
										Qase Platform&nbsp;for your own records,
										prior to the deactivation of your
										account.&nbsp;&nbsp;
									</p>

									<hr />
									<p>
										<strong>Schedule A</strong>
									</p>

									<p>&nbsp;</p>

									<p>
										<strong>Qase Policies</strong>
									</p>

									<p>Qase policies are as follows:</p>

									<ul>
										<li>
											BNBR – Be Nice, Be Respectful;&nbsp;
										</li>
										<li>Be who you say you are;&nbsp;</li>
										<li>
											No shopping for free advice - don’t
											take the same problem to multiple
											lawyers without a good reason;&nbsp;
										</li>
										<li>
											Do not use the Qase&nbsp;Platform to
											engage in illegal activity of any
											kind;&nbsp;
										</li>
										<li>
											Help us help you have a better
											experience by providing feedback on
											Qase and your lawyer; and&nbsp;
										</li>
										<li>
											Keep copies of&nbsp;all
											important&nbsp;files to ensure they
											are available when you need them.
										</li>
									</ul>

									<hr />
									<p>
										Copyright 2019 Qase Inc. All rights
										reserved. No part of Qase's Website may
										be reproduced, modified, or distributed
										in any form or manner without the prior
										written permission of Qase.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<GetStartedBlock />
		</div>
	);
};

export default Terms;
