import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";

import { Player, BigPlayButton } from "video-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";

import { axios } from "../../Core";
import { getPracticeAreaLink } from "../../Utils/Helpers";
import { Testimonials, PASeoHeading, LawyerBlock } from "../../Components";
import UsersIcon from "../../Images/PASeo/Users.svg";
import CanadianIcon from "../../Images/PASeo/Group10.svg";
import InstantLegalIcon from "../../Images/PASeo/Group11.svg";
import CardsIcon from "../../Images/PASeo/Cards.svg";
import VacasyIcon from "../../Images/PASeo/vacay.svg";
import CheckIcon from "../../Images/check-icon.svg";

import { getPASeoHeaderTexts } from "../../Utils/Helpers";

import "./PracticeAreaSeo.scss";

const PracticeAreaSeo = (props) => {
    const headerInfo = getPASeoHeaderTexts(props.location.pathname);
    const [practiceArea, setPracticeArea] = useState({});

    const fetchPracticeArea = async (location) => {
        let path = !!headerInfo.request_url
            ? headerInfo.request_url.split("_").join(" ")
            : location.split("/")[2].split("_").join(" ");
        let formData = new FormData();
        formData.append("area_name", path);
        const response = await axios.post(
            "/api/v1/marketing/practice_areas",
            formData
        );
        //console.log(response);
        if (response.data.success) {
            setPracticeArea(response.data.data);
        }
    };

    useEffect(() => {
        fetchPracticeArea(props.location.pathname);
    }, [props.location.pathname]);

    console.log(headerInfo);

    return (
        <div id="pa-seo">
            <PASeoHeading {...headerInfo} />

            <section className="how-it-works">
                <div className="q-pb-45 q-pt-25 q-bg-midnightblue text-white">
                    <div className="container">
                        <div className="partner-logos">
                            <p className="q-color-white text-center mb-2">
                                As featured on
                            </p>
                            <div className="row justify-content-center align-items-center">
                                <div className="partner-logo-item col-4 col-md-auto">
                                    <img
                                        className="img-fluid law-logo"
                                        src={require("../../Images/PartnerLogos/law360@2x.png")}
                                        alt="Law 360"
                                    />
                                </div>
                                <div className="partner-logo-item col-4 col-md-auto">
                                    <img
                                        className="img-fluid straigth-logo"
                                        src={require("../../Images/PartnerLogos/straight@2x.png")}
                                        alt="Law 360"
                                    />
                                </div>
                                <div className="partner-logo-item col-4 col-md-auto">
                                    <img
                                        className="img-fluid bb-tech-logo"
                                        src={require("../../Images/PartnerLogos/bb-tech@2x.png")}
                                        alt="Law 360"
                                    />
                                </div>
                                <div className="partner-logo-item col-3 col-md-auto">
                                    <img
                                        className="img-fluid acba-logo"
                                        src={require("../../Images/PartnerLogos/acba@2x.png")}
                                        alt="Law 360"
                                    />
                                </div>
                                <div className="partner-logo-item col-3 col-md-auto">
                                    <img
                                        className="img-fluid b-vancouver-logo"
                                        src={require("../../Images/PartnerLogos/b-vancouver@2x.png")}
                                        alt="Law 360"
                                    />
                                </div>
                                <div className="partner-logo-item col-3 col-md-auto">
                                    <img
                                        className="img-fluid access-pro-bono-logo"
                                        src={require("../../Images/PartnerLogos/access-pro-bono@2x.png")}
                                        alt="Law 360"
                                    />
                                </div>
                                <div className="partner-logo-item col-3 col-md-auto">
                                    <img
                                        className="img-fluid globe-logo"
                                        src={require("../../Images/PartnerLogos/globe@2x.png")}
                                        alt="Law 360"
                                    />
                                </div>
                            </div>
                        </div>
                        <h1 className="text-center q-mb-39">How it Works</h1>
                        <div className="q-mb-22">
                            <div className="q-mb-56">
                                <div className="row">
                                    <div className="col-md-6 align-self-center order-md-2">
                                        <div className="q-mb-1_3">
                                            <Player
                                                playsInline
                                                poster={require("../../Images/PPC/video-bg.jpg")}
                                                src={require("../../Videos/VideoDemoV1.mov")}
                                            >
                                                <BigPlayButton position="center" />
                                            </Player>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="list-unstyled">
                                            <li className="media q-mb-60">
                                                <div className="circled-wrapper mr-3">
                                                    <FontAwesomeIcon
                                                        icon={faCheckCircle}
                                                        color="#ffffff"
                                                        size="2x"
                                                    />
                                                </div>
                                                <div className="media-body">
                                                    <h3>
                                                        Step 1: Book Free
                                                        Consult
                                                    </h3>
                                                    <p className="mb-0">
                                                        Use our online calendar
                                                        tool to select a lawyer
                                                        <br />
                                                        who can assist you with
                                                        your legal matter.
                                                    </p>
                                                </div>
                                            </li>
                                            <li className="media q-mb-60">
                                                <div className="circled-wrapper mr-3">
                                                    <FontAwesomeIcon
                                                        icon={faCheckCircle}
                                                        color="#ffffff"
                                                        size="2x"
                                                    />
                                                </div>
                                                <div className="media-body">
                                                    <h3>
                                                        Step 2: Meet by Phone or
                                                        Video
                                                    </h3>
                                                    <p className="mb-0">
                                                        Arrange to meet with a
                                                        lawyer by either phone
                                                        or <br />
                                                        video chat. Connect
                                                        entirely online!
                                                    </p>
                                                </div>
                                            </li>
                                            <li className="media q-mb-60">
                                                <div className="circled-wrapper mr-3">
                                                    <FontAwesomeIcon
                                                        icon={faCheckCircle}
                                                        color="#ffffff"
                                                        size="2x"
                                                    />
                                                </div>
                                                <div className="media-body">
                                                    <h3>
                                                        Step 3: Pay-As-You-Go
                                                    </h3>
                                                    <p className="mb-0">
                                                        Work with your lawyer
                                                        online and pay by credit{" "}
                                                        <br />
                                                        card through our secured
                                                        online payment <br />
                                                        platform.
                                                    </p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                                <a
                                    className="btn btn-melon d-none d-md-inline-block desktop"
                                    href={headerInfo.link}
                                >
                                    Book a Free Consult Now
                                </a>
                                <a
                                    className="btn btn-melon d-md-none mobile"
                                    href={headerInfo.mobile_link}
                                >
                                    Book a Free Consult Now
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="qase-perks position-relative">
                <div
                    className="divider divider-top wave flip-x"
                    style={{ position: "relative", maxHeight: "none" }}
                >
                    <svg
                        width="102%"
                        clipRule="evenodd"
                        fillRule="evenodd"
                        strokeLinejoin="round"
                        strokeMiterlimit="2"
                        viewBox="0 0 4992 596"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="m-4.167 88.792c177.084-45.221 336.804-67.83 479.167-67.83 213.542 0 765.625 88.7 989.583 118.267 223.959 29.567 380.529 32.571 545.138 26.088 309.029-12.175 482.642-60.871 772.571-76.525 142.4-7.688 502.116 9.779 883.679 90.437 381.562 80.659 701.391 201.75 888.891 219.142 125 11.592 271.988 5.796 440.971-17.392v-389.312h-5000v97.125z"
                            fill="#fff"
                            className="mono"
                        />
                    </svg>
                </div>

                <div className="">
                    <div className="container">
                        <div className="q-mb-50">
                            <h1 className="q-color-midnightblue text-center mb-0">
                                The Qase Solution
                            </h1>
                        </div>
                        <div className="row justify-content-xl-center">
                            <div className="col-xl-10">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="card rounded-1 mb-4">
                                            <div className="card-body">
                                                <p className="p2 m-0">
                                                    {headerInfo.qase_solution}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="list-unstyled ml-md-4 mb-4">
                                            <li className="media q-mb-33">
                                                <img
                                                    src={CheckIcon}
                                                    alt="Check"
                                                    className="mr-3"
                                                />
                                                <div className="media-body">
                                                    <p className="mb-0 font-weight-bold p2">
                                                        A smarter way to search
                                                        and find lawyers in
                                                        British Columbia
                                                    </p>
                                                </div>
                                            </li>
                                            <li className="media q-mb-33">
                                                <img
                                                    src={CheckIcon}
                                                    alt="Check"
                                                    className="mr-3"
                                                />
                                                <div className="media-body">
                                                    <p className="mb-0 font-weight-bold p2">
                                                        No surprise bills!
                                                    </p>
                                                </div>
                                            </li>
                                            <li className="media q-mb-33">
                                                <img
                                                    src={CheckIcon}
                                                    alt="Check"
                                                    className="mr-3"
                                                />
                                                <div className="media-body">
                                                    <p className="mb-0 font-weight-bold p2">
                                                        Easy online bookings
                                                    </p>
                                                </div>
                                            </li>
                                            <li className="media q-mb-33">
                                                <img
                                                    src={CheckIcon}
                                                    alt="Check"
                                                    className="mr-3"
                                                />
                                                <div className="media-body">
                                                    <p className="mb-0 font-weight-bold p2">
                                                        Helpful reminders
                                                    </p>
                                                </div>
                                            </li>
                                            <li className="media q-mb-33">
                                                <img
                                                    src={CheckIcon}
                                                    alt="Check"
                                                    className="mr-3"
                                                />
                                                <div className="media-body">
                                                    <p className="mb-0 font-weight-bold p2">
                                                        Your information and
                                                        details are safe and
                                                        secure with us
                                                    </p>
                                                </div>
                                            </li>
                                            <li className="media">
                                                <img
                                                    src={CheckIcon}
                                                    alt="Check"
                                                    className="mr-3"
                                                />
                                                <div className="media-body">
                                                    <p className="mb-0 font-weight-bold p2">
                                                        Video chats and phone
                                                        chats make it easy to
                                                        keep in touch
                                                    </p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="text-center q-mt-58">
                            <a
                                className="btn btn-melon d-none d-md-inline-block"
                                href={headerInfo.link}
                            >
                                Book a Free Consult Now
                            </a>
                            <a
                                className="btn btn-melon d-md-none"
                                href={headerInfo.mobile_link}
                            >
                                Book a Free Consult Now
                            </a>
                        </div>
                    </div>
                </div>
                <div
                    className="divider divider-bottom wave-gray reverse"
                    style={{ position: "relative", maxHeight: "none" }}
                >
                    <svg
                        width="102%"
                        clipRule="evenodd"
                        fillRule="evenodd"
                        strokeLinejoin="round"
                        strokeMiterlimit="2"
                        viewBox="0 0 4992 596"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="m-4.167 88.792c177.084-45.221 336.804-67.83 479.167-67.83 213.542 0 765.625 88.7 989.583 118.267 223.959 29.567 380.529 32.571 545.138 26.088 309.029-12.175 482.642-60.871 772.571-76.525 142.4-7.688 502.116 9.779 883.679 90.437 381.562 80.659 701.391 201.75 888.891 219.142 125 11.592 271.988 5.796 440.971-17.392v-389.312h-5000v97.125z"
                            fill="#fff"
                            className="mono"
                        ></path>
                    </svg>
                </div>
            </section>

            <section className="faqs">
                <div className="q-bg-whitesmoke q-py-40 q-pt-md-94 q-pb-md-76">
                    <div className="container-xl">
                        <h1 className="q-color-midnightblue text-center q-mb-md-42 q-mb-32">
                            Frequently Asked Questions
                        </h1>
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <div className="accordion-wrapper q-mb-56">
                                    {headerInfo.faqs &&
                                        headerInfo.faqs.map((item, index) => {
                                            return (
                                                <div
                                                    className="q-mb-24 q-mb-md-32"
                                                    key={index}
                                                >
                                                    <details className="accordion-item">
                                                        <summary>
                                                            <div className="accordion_item__accordion-title">
                                                                <h3 className="m-0">
                                                                    {item.title}
                                                                </h3>
                                                            </div>
                                                        </summary>
                                                        <div className="accordion_item__accordion-body">
                                                            {item.content}
                                                        </div>
                                                    </details>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="text-center">
                            <a
                                className="btn btn-melon d-none d-md-inline-block"
                                href={headerInfo.link}
                            >
                                Book a Free Consult Now
                            </a>
                            <a
                                className="btn btn-melon d-md-none"
                                href={headerInfo.mobile_link}
                            >
                                Book a Free Consult Now
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="why-choose-qase position-relative">
                <div className="divider divider-top wave-gray flip-x">
                    <svg
                        width="102%"
                        clipRule="evenodd"
                        fillRule="evenodd"
                        strokeLinejoin="round"
                        strokeMiterlimit="2"
                        viewBox="0 0 4992 596"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="m-4.167 88.792c177.084-45.221 336.804-67.83 479.167-67.83 213.542 0 765.625 88.7 989.583 118.267 223.959 29.567 380.529 32.571 545.138 26.088 309.029-12.175 482.642-60.871 772.571-76.525 142.4-7.688 502.116 9.779 883.679 90.437 381.562 80.659 701.391 201.75 888.891 219.142 125 11.592 271.988 5.796 440.971-17.392v-389.312h-5000v97.125z"
                            fill="#fff"
                            className="mono"
                        />
                    </svg>
                </div>
                <div className="q-bg-midnightblue q-py-65 q-pt-md-161 q-pb-md-100">
                    <div className="container">
                        <div className="text-center text-white q-mb-56">
                            <h1 className="">Why Choose Qase?</h1>
                            <p className="p2 mb-0">
                                Here's some of the reasons people choose to use
                                Qase for all their legal needs
                            </p>
                        </div>
                        <div className="q-mb-149">
                            <div className="row justify-content-md-between justify-content-center">
                                <div className="col-4 col-md-auto">
                                    <div className="legal-item">
                                        <div className="legal-item-image mb-3">
                                            <img
                                                src={UsersIcon}
                                                alt="Over 1000 users"
                                            />
                                        </div>
                                        <h3 className="text-white">
                                            Over 1000 <br /> users
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-4 col-md-auto">
                                    <div className="legal-item">
                                        <div className="legal-item-image mb-3">
                                            <img
                                                src={CanadianIcon}
                                                alt="Canadian company"
                                            />
                                        </div>
                                        <h3 className="text-white">
                                            Canadian <br />
                                            company
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-4 col-md-auto">
                                    <div className="legal-item">
                                        <div className="legal-item-image mb-3">
                                            <img
                                                src={InstantLegalIcon}
                                                alt="Instant legal advice"
                                            />
                                        </div>
                                        <h3 className="text-white">
                                            Fast legal <br /> advice
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-4 col-md-auto">
                                    <div className="legal-item mb-3">
                                        <div className="legal-item-image">
                                            <img
                                                src={CardsIcon}
                                                alt="No surprise legal bills"
                                            />
                                        </div>
                                        <h3 className="text-white">
                                            No surprise <br /> legal bills
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-4 col-md-auto">
                                    <div className="legal-item">
                                        <div className="legal-item-image mb-3">
                                            <img
                                                src={VacasyIcon}
                                                alt="Meeting flexibility"
                                            />
                                        </div>
                                        <h3 className="text-white">
                                            Meeting <br /> flexibility
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h1 className="text-white text-center q-mb-40">
                            Testimonials
                        </h1>

                        <div className="row">
                            <div className="col-md-4">
                                <Testimonials
                                    text={
                                        headerInfo.testimonialsBody
                                            ? headerInfo.testimonialsBody[0]
                                                  .firstSection
                                            : "Excellent guidance. Immediate responses. Very good following-ups."
                                    }
                                    name={
                                        headerInfo.testimonialsTitle
                                            ? headerInfo.testimonialsTitle[0]
                                                  .firstSection
                                            : "Family Law Client"
                                    }
                                />
                            </div>
                            <div className="col-md-4">
                                <Testimonials
                                    text={
                                        headerInfo.testimonialsBody
                                            ? headerInfo.testimonialsBody[1]
                                                  .secondSection
                                            : "My lawyer was pleasant and helpful. She provided me with the information I needed as well as additional information. She really helped me out with what I was going through. Thanks for having this service."
                                    }
                                    name={
                                        headerInfo.testimonialsTitle
                                            ? headerInfo.testimonialsTitle[1]
                                                  .secondSection
                                            : "Family Law Client"
                                    }
                                />
                            </div>
                            <div className="col-md-4">
                                <Testimonials
                                    text={
                                        headerInfo.testimonialsBody
                                            ? headerInfo.testimonialsBody[2]
                                                  .thirdSection
                                            : "The process to set up the consultation was simple and easy."
                                    }
                                    name={
                                        headerInfo.testimonialsTitle
                                            ? headerInfo.testimonialsTitle[2]
                                                  .thirdSection
                                            : "Family Law Client"
                                    }
                                    is_revers={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="get-started position-relative q-bg-whitesmoke">
                <div className="divider divider-top wave flip-x">
                    <svg
                        width="102%"
                        clipRule="evenodd"
                        fillRule="evenodd"
                        strokeLinejoin="round"
                        strokeMiterlimit="2"
                        viewBox="0 0 4992 596"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="m-4.167 88.792c177.084-45.221 336.804-67.83 479.167-67.83 213.542 0 765.625 88.7 989.583 118.267 223.959 29.567 380.529 32.571 545.138 26.088 309.029-12.175 482.642-60.871 772.571-76.525 142.4-7.688 502.116 9.779 883.679 90.437 381.562 80.659 701.391 201.75 888.891 219.142 125 11.592 271.988 5.796 440.971-17.392v-389.312h-5000v97.125z"
                            fill="#fff"
                            className="mono"
                        />
                    </svg>
                </div>
                <div className="q-py-65 q-pt-md-143 q-pb-md-90">
                    <div className="container">
                        <div className="text-center q-mb-40">
                            <div className="row justify-content-center">
                                <div className="col-md-8 col-lg-7">
                                    <h1 className="q-color-midnightblue">
                                        Get Started With Qase Today
                                    </h1>
                                    <p className="p2">
                                        Ready to talk to a lawyer now? <br />
                                        {!!headerInfo.get_started
                                            ? headerInfo.get_started
                                            : "You are just a few clicks away from a family lawyer who can help."}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="q-mb-104">
                            <div className="row justify-content-center">
                                {practiceArea.has_children &&
                                    practiceArea.children.length > 0 &&
                                    practiceArea.children.map((pr_area) => {
                                        let link = getPracticeAreaLink(pr_area);
                                        return (
                                            <div
                                                className="col-md-4 mb-3"
                                                key={pr_area.id}
                                            >
                                                <div className="card practice-block-2">
                                                    <div className="card-body">
                                                        <h3>
                                                            <Link to={link}>
                                                                {!!pr_area.title
                                                                    ? pr_area.title
                                                                    : pr_area.name}
                                                            </Link>
                                                        </h3>
                                                        {pr_area.description && (
                                                            <div
                                                                className="p2"
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        pr_area.description,
                                                                }}
                                                            ></div>
                                                        )}
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <Link
                                                            className="btn btn-melon"
                                                            to={link}
                                                        >
                                                            Read more
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}

                                {practiceArea.id ===
                                    process.env.REACT_APP_FAMILY_LAW && (
                                    <Fragment>
                                        <div className="col-md-4 mb-3">
                                            <div className="card practice-block-2">
                                                <div className="card-body">
                                                    <h3>
                                                        <Link
                                                            to={`/practice-area/${process.env.REACT_APP_PR_SEO_SPOUSAL_SUPPORT}`}
                                                        >
                                                            Spousal Support
                                                        </Link>
                                                    </h3>
                                                    <div className="p2">
                                                        <p>
                                                            Spousal support
                                                            helps ensure that a
                                                            former partner does
                                                            not struggle
                                                            financially while
                                                            becoming
                                                            independent.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="card-footer text-center">
                                                    <Link
                                                        className="btn btn-melon"
                                                        to={`/practice-area/${process.env.REACT_APP_PR_SEO_SPOUSAL_SUPPORT}`}
                                                    >
                                                        Read more
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div className="card practice-block-2">
                                                <div className="card-body">
                                                    <h3>
                                                        <Link
                                                            to={`/practice-area/${process.env.REACT_APP_PR_SEO_CHILD_SUPPORT}`}
                                                        >
                                                            Child Support
                                                        </Link>
                                                    </h3>
                                                    <div className="p2">
                                                        <p>
                                                            Child support means
                                                            each parent's
                                                            financial
                                                            responsibility for
                                                            the children.
                                                            Children are legally
                                                            entitled to support
                                                            from the parent who
                                                            does not live with
                                                            them.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="card-footer text-center">
                                                    <Link
                                                        className="btn btn-melon"
                                                        to={`/practice-area/${process.env.REACT_APP_PR_SEO_CHILD_SUPPORT}`}
                                                    >
                                                        Read more
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div className="card practice-block-2">
                                                <div className="card-body">
                                                    <h3>
                                                        <Link
                                                            to={`/practice-area/${process.env.REACT_APP_PR_SEO_MATRIMONIAL_HOME}`}
                                                        >
                                                            Matrimonial Home
                                                        </Link>
                                                    </h3>
                                                    <div className="p2">
                                                        <p>
                                                            Newly separated
                                                            couples often must
                                                            determine who should
                                                            stay in their home.
                                                            When there is a
                                                            disagreement, a
                                                            court can grant one
                                                            party exclusive
                                                            possession.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="card-footer text-center">
                                                    <Link
                                                        className="btn btn-melon"
                                                        to={`/practice-area/${process.env.REACT_APP_PR_SEO_MATRIMONIAL_HOME}`}
                                                    >
                                                        Read more
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                )}

                                {practiceArea.id ===
                                    process.env.REACT_APP_WILLS_AND_ESTATES && (
                                    <Fragment>
                                        <div className="col-md-4 mb-3">
                                            <div className="card practice-block-2">
                                                <div className="card-body">
                                                    <h3>
                                                        <Link
                                                            to={`/practice-area/${process.env.REACT_APP_PR_SEO_ESTATE_LIITIGATION}`}
                                                        >
                                                            Estate Litigation
                                                        </Link>
                                                    </h3>
                                                    <div className="p2">
                                                        <p>
                                                            Handling an estate
                                                            can create disputes.
                                                            Getting legal advice
                                                            early can make sure
                                                            that your rights are
                                                            protected.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="card-footer text-center">
                                                    <Link
                                                        className="btn btn-melon"
                                                        to={`/practice-area/${process.env.REACT_APP_PR_SEO_ESTATE_LIITIGATION}`}
                                                    >
                                                        Read more
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                )}

                                {practiceArea.show_lawyers &&
                                    practiceArea.lawyers.length > 0 &&
                                    practiceArea.lawyers.map((lawyer) => {
                                        return (
                                            <div
                                                key={lawyer.uuid}
                                                className="col-sm-6 col-md-6 col-lg-4 qr-my-3"
                                            >
                                                <LawyerBlock
                                                    full_name={`${lawyer.first_name} ${lawyer.last_name}`}
                                                    areas={
                                                        lawyer.practice_areas
                                                    }
                                                    profile_picture={
                                                        lawyer.profile_picture
                                                    }
                                                    link_url={`/lawyer/${lawyer.uuid}`}
                                                    practice_start={
                                                        lawyer.practice_start_date
                                                    }
                                                />
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>

                        <div className="divider-hr"></div>

                        <h3 className="text-center mb-3">
                            Request your FREE legal consultation now
                        </h3>
                        <div className="text-center">
                            <a
                                className="btn btn-melon d-none d-md-inline-block"
                                href={headerInfo.link}
                            >
                                Book a Free Consult Now
                            </a>
                            <a
                                className="btn btn-melon d-md-none"
                                href={headerInfo.mobile_link}
                            >
                                Book a Free Consult Now
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default PracticeAreaSeo;
