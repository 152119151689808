export default (path) => {
    switch (path) {
        case "/family_law_ppc":
            return {
                title: "Need Legal Help on a Family Matter?",
                description:
                    "Book an online consultation with a family lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_FAMILY_LAW}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_FAMILY_LAW}`,
            };
        case "/child_custody_ppc":
            return {
                title: "Need Legal Help on a Child Custody Matter?",
                description:
                    "Book an online consultation with a child custody lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_CHILD_CUSTODY}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_CHILD_CUSTODY}`,
            };
        case "/separation_divorce_ppc":
            return {
                title: "Need Legal Help on a Separation or Divorce Matter?",
                description:
                    "Book an online consultation with a separation and divorce lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_SEPARATION_AND_DIVORCE}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_SEPARATION_AND_DIVORCE}`,
            };
        case "/child_support_ppc":
            return {
                title: "Need Legal Help on a Child Support Matter?",
                description:
                    "Book an online consultation with a child support lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_SPOUSAL_OR_CHILD_SUPPORT}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_SPOUSAL_OR_CHILD_SUPPORT}`,
            };
        case "/spousal_support_ppc":
            return {
                title: "Need Legal Help on a Spousal Support Matter?",
                description:
                    "Book an online consultation with a spousal support lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_SPOUSAL_OR_CHILD_SUPPORT}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_SPOUSAL_OR_CHILD_SUPPORT}`,
            };
        case "/high_net-worth_divorce_ppc":
            return {
                title: "Need Legal Help on a High-Net Worth Divorce Matter?",
                description:
                    "Book an online consultation with a high-net worth divorce lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_SEPARATION_AND_DIVORCE}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_SEPARATION_AND_DIVORCE}`,
            };
        case "/property_division_ppc":
            return {
                title: "Need Legal Help on a Property Division Matter?",
                description:
                    "Book an online consultation with a property division lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_SEPARATION_AND_DIVORCE}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_SEPARATION_AND_DIVORCE}`,
            };
        case "/marriage_agreements_ppc":
            return {
                title: "Need Legal Help on a Marriage Agreement?",
                description:
                    "Book an online consultation with a marriage agreement lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_PRENUPTIAL}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_PRENUPTIAL}`,
            };
        case "/separation_agreements_ppc":
            return {
                title: "Need Legal Help on a Separation Agreement?",
                description:
                    "Book an online consultation with a separation agreement lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_SEPARATION_AND_DIVORCE}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_SEPARATION_AND_DIVORCE}`,
            };
        case "/collaborative_family_law_ppc":
            return {
                title: "Need Legal Help on a Collaborative Family Law Matter?",
                description:
                    "Book an online consultation with a collaborative family lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_FAMILY_LAW}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_FAMILY_LAW}`,
            };
        case "/emergency_enforcement_orders_ppc":
            return {
                title: "Need Legal Help on a Collaborative Family Law Matter?",
                description:
                    "Book an online consultation with a collaborative family lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_FAMILY_LAW}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_FAMILY_LAW}`,
            };
        case "/family_lawyer_ppc":
            return {
                title: "Need Legal Help from a Family Lawyer?",
                description:
                    "Book an online consultation with a family lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_FAMILY_LAW}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_FAMILY_LAW}`,
            };
        case "/family_law_attorney_ppc":
            return {
                title: "Need Legal Help from a Family Law Attorney?",
                description:
                    "Book an online consultation with a family law attorney in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_FAMILY_LAW}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_FAMILY_LAW}`,
            };
        case "/divorce_lawyer_ppc":
            return {
                title: "Need Legal Help from a Divorce Lawyer?",
                description:
                    "Book an online consultation with a divorce lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_SEPARATION_AND_DIVORCE}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_SEPARATION_AND_DIVORCE}`,
            };
        case "/divorce_attorney_ppc":
            return {
                title: "Need Legal Help from a Divorce Attorney?",
                description:
                    "Book an online consultation with a divorce attorney in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_SEPARATION_AND_DIVORCE}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_SEPARATION_AND_DIVORCE}`,
            };
        case "/adoptions_ppc":
            return {
                title: "Need Legal Help on an Adoption Matter?",
                description:
                    "Book an online consultation with an adoption lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_ADOPTION}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_ADOPTION}`,
            };
        case "/alternative_dispute_resolution_ppc":
            return {
                title:
                    "Need Legal Help on an Alternative Dispute Resolution Matter?",
                description:
                    "Book an online consultation with an alternative dispute resolution lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_FAMILY_LAW}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_FAMILY_LAW}`,
            };
        case "/cohabitation_agreements_ppc":
            return {
                title: "Need Legal Help on a Cohabitation Agreement?",
                description:
                    "Book an online consultation with a cohabitation agreement lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_PRENUPTIAL}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_PRENUPTIAL}`,
            };
        case "/matrimonial_home_ppc":
            return {
                title: "Need Legal Help on a Matrimonial Home Matter?",
                description:
                    "Book an online consultation with a matrimonial home lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_FAMILY_LAW}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_FAMILY_LAW}`,
            };
        case "/prenup_cohab_agreements_ppc":
            return {
                title: "Need Legal Help on a Prenup or Cohabitation Matter?",
                description:
                    "Book an online consultation with a prenup or cohabitation lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_PRENUPTIAL}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_PRENUPTIAL}`,
            };
        case "/wills_estates_ppc":
            return {
                title: "Need Legal Help on a Wills and Estates Matter?",
                description:
                    "Book an online consultation with a wills and estates lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
            };
        case "/wills_powers_attorney_ppc":
            return {
                title:
                    "Need Legal Help on a Wills and Powers of Attorney Matter?",
                description:
                    "Book an online consultation with a wills and powers of attorney lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
            };
        case "/succession_planning_ppc":
            return {
                title: "Need Legal Help on a Succession Planning Matter?",
                description:
                    "Book an online consultation with a succession planning lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
            };
        case "/capacity_claims_ppc":
            return {
                title: "Need Legal Help on a Capacity Claims Matter?",
                description:
                    "Book an online consultation with capacity claims lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
            };
        case "/dependents_relief_ppc":
            return {
                title: "Need Legal Help on a Dependent's Relief Matter?",
                description:
                    "Book an online consultation with a dependent's relief lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
            };
        case "/passing_accounts_ppc":
            return {
                title: "Need Legal Help on a Passing of Accounts Matter?",
                description:
                    "Book an online consultation with passing of accounts lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
            };
        case "/removal_executor_ppc":
            return {
                title: "Need Legal Help on a Removal of an Executor?",
                description:
                    "Book an online consult with a removal of executor lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
            };
        case "/trust_disputes_ppc":
            return {
                title: "Need Legal Help on a Trust Dispute Matter?",
                description:
                    "Book an online consultation with a trust dispute lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
            };
        case "/estates_trusts_ppc":
            return {
                title: "Need Legal Help on an Estate and Trusts Matter?",
                description:
                    "Book an online consultation with an estates and trusts lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
            };
        case "/guardianships_ppc":
            return {
                title: "Need Legal Help on a Guardianship Matter?",
                description:
                    "Book an online consultation with a guardianship lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_GUARDIANSHIP}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_GUARDIANSHIP}`,
            };
        case "/advising_fiduciaries_ppc":
            return {
                title: "Need Legal Help on a Fiduciary Matter?",
                description:
                    "Book an online consultation with a lawyer that handles fiduciary matters in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
            };
        case "/capacity_proceedings_ppc":
            return {
                title: "Need Legal Help on a Capacity Proceeding?",
                description:
                    "Book an online consult with a capacity proceeding lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
            };
        case "/representations_persons_disability_ppc":
            return {
                title:
                    "Need Legal Help on a Disability Representations Matter?",
                description:
                    "Book an online consultation with a disability representations lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
            };
        case "/probate_applications_ppc":
            return {
                title: "Need Legal Help on a Probate Applications Matter?",
                description:
                    "Book an online consultation with a probate applications lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_PROBATE_AND_ESTATE}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_PROBATE_AND_ESTATE}`,
            };
        case "/challenges_executors_ppc":
            return {
                title: "Need Legal Help on Challenging an Executor?",
                description:
                    "Book an online consultation with a lawyer that handles executor challenges in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
            };
        case "/estate_litigation_ppc":
            return {
                title: "Need Legal Help on an Estate Litigation Matter?",
                description:
                    "Book an online consultation with an estate litigation lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
            };
        case "/employment_benefits_ppc":
            return {
                title: "Need Legal Help on an Employment and Benefits Matter?",
                description:
                    "Book an online consultation with an employment and benefits lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_EMPLOYMENTS_AND_BENEFITS}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_EMPLOYMENTS_AND_BENEFITS}`,
            };
        case "/employment_contracts_ppc":
            return {
                title: "Need Legal Help on an Employment Contracts Matter?",
                description:
                    "Book an online consultation with an employment contracts lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_EMPLOYMENT_CONTRACTS}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_EMPLOYMENT_CONTRACTS}`,
            };
        case "/severance_package_ppc":
            return {
                title: "Need Legal Help on a Severance Package Review?",
                description:
                    "Book an online consultation with a severance package review lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_EMPLOYMENT_CONTRACTS}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_EMPLOYMENT_CONTRACTS}`,
            };
        case "/wrongful_dismissal_ppc":
            return {
                title: "Need Legal Help on a Wrongful Dismissal Matter?",
                description:
                    "Book an online consultation with a wrongful dismissal lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_WRONGFUL_DISMISSAL}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_WRONGFUL_DISMISSAL}`,
            };
        case "/harassment_discrimination_workplace_ppc":
            return {
                title:
                    "Need Legal Help on a Harassment and Discrimination Matter?",
                description:
                    "Book an online consultation with a harassment and discrimination lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_EMPLOYMENTS_AND_BENEFITS}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_EMPLOYMENTS_AND_BENEFITS}`,
            };
        case "/labour_lawyers_ppc":
            return {
                title: "Need Legal Help from a Labour Lawyer?",
                description:
                    "Book an online consultation with a labour lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_EMPLOYMENTS_AND_BENEFITS}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_EMPLOYMENTS_AND_BENEFITS}`,
            };
        case "/employment_lawyers_ppc":
            return {
                title: "Need Legal Help from an Employment Lawyer?",
                description:
                    "Book an online consultation with an employment lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_EMPLOYMENTS_AND_BENEFITS}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_EMPLOYMENTS_AND_BENEFITS}`,
            };
        case "/constructive_dismissal_ppc":
            return {
                title: "Need Legal Help on a Constructive Dismissal Matter?",
                description:
                    "Book an online consultation with a constructive dismissal lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_EMPLOYMENTS_AND_BENEFITS}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_EMPLOYMENTS_AND_BENEFITS}`,
            };
        case "/termination_without_cause_ppc":
            return {
                title: "Need Legal Help on a Termination Without Cause Matter?",
                description:
                    "Book an online consultation with an employment lawyer that handles termination without cause in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_EMPLOYMENT_CONTRACTS}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_EMPLOYMENT_CONTRACTS}`,
            };
        case "/termination_for_cause_ppc":
            return {
                title: "Need Legal Help on a Termination For Cause Matter?",
                description:
                    "Book an online consultation with an employment lawyer that handles termination for cause in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_EMPLOYMENT_CONTRACTS}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_EMPLOYMENT_CONTRACTS}`,
            };
        case "/ministry_labour_complaints_ppc":
            return {
                title:
                    "Need Legal Help on a Ministry of Labour Complaints Matter?",
                description:
                    "Book an online consultation with a labour complaints lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_EMPLOYMENTS_AND_BENEFITS}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_EMPLOYMENTS_AND_BENEFITS}`,
            };
        case "/duty_to_accommodate_ppc":
            return {
                title: "Need Legal Help on a Duty to Accommodate Matter?",
                description:
                    "Book an online consultation with a human rights lawyer that handles duty to accommodate claims in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_EMPLOYMENTS_AND_BENEFITS}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_EMPLOYMENTS_AND_BENEFITS}`,
            };
        case "/employment_insurance_ppc":
            return {
                title: "Need Legal Help on an Employment Insurance Matter?",
                description:
                    "Book an online consultation with an employment insurance lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_EMPLOYMENTS_AND_BENEFITS}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_EMPLOYMENTS_AND_BENEFITS}`,
            };
        case "/temporary_layoffs_ppc":
            return {
                title: "Need Legal Help on a Temporary Layoff Matter?",
                description:
                    "Book an online consultation with an employment lawyer that handles temporary layoffs in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_EMPLOYMENTS_AND_BENEFITS}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_EMPLOYMENTS_AND_BENEFITS}`,
            };
        case "/workplace_investigation_conflict_ppc":
            return {
                title:
                    "Need Legal Help on a Workplace Investigations and Conflict Matter?",
                description:
                    "Book an online consultation with an employment lawyer that handles workplace investigations in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_EMPLOYMENTS_AND_BENEFITS}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_EMPLOYMENTS_AND_BENEFITS}`,
            };
        case "/employment_standards_compliance_ppc":
            return {
                title:
                    "Need Legal Help on an Employment Standards Compliance Matter?",
                description:
                    "Book an online consultation with an employment standards compliance lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_EMPLOYMENT_CONTRACTS}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_EMPLOYMENT_CONTRACTS}`,
            };
        case "/performance_mgmt_discipline_ppc":
            return {
                title:
                    "Need Legal Help on a Performance Management & Discipline Matter?",
                description:
                    "Book an online consultation with a performance management and discipline lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_EMPLOYMENTS_AND_BENEFITS}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_EMPLOYMENTS_AND_BENEFITS}`,
            };
        case "/privacy_workplace_ppc":
            return {
                title: "Need Legal Help on a Workplace Privacy Matter?",
                description:
                    "Book an online consultation with a workplace privacy lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_EMPLOYMENTS_AND_BENEFITS}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_EMPLOYMENTS_AND_BENEFITS}`,
            };
        case "/employee_termination_ppc":
            return {
                title: "Need Legal Help on an Employee Termination Matter?",
                description:
                    "Book an online consultation with an employee termination lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_EMPLOYMENT_CONTRACTS}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_EMPLOYMENT_CONTRACTS}`,
            };
        case "/wrongful_dismissal_discrimination_ppc":
            return {
                title:
                    "Need Legal Help on a Wrongful Dismissal or Discrimination Matter?",
                description:
                    "Book an online consultation with a wrongful dismissal or discrimination lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_WRONGFUL_DISMISSAL}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_WRONGFUL_DISMISSAL}`,
            };
        default:
            return {
                title: "",
                description: "",
                link: "",
                mobile_link: "",
            };
    }
};
