import React, { Fragment, useState, useEffect } from "react";

import { axios } from "../../Core";

import { GetStartedBlock, BlueHero, PracticeAreaBlock } from "../../Components";
import { getPracticeAreaLink } from "../../Utils/Helpers";

const PracticeAreas = () => {
    const [isLoading, setLoading] = useState(true);
    const [practiceAreas, setPracticeAreas] = useState([]);

    useEffect(() => {
        axios.post("/api/v1/marketing/practice_areas").then((res) => {
            if (res.data.success) {
                //console.log(res.data.data);
                setPracticeAreas(res.data.data);
                setLoading(false);
            }
        });
    }, []);

    return (
        <div>
            <BlueHero
                title="Get legal help"
                descriptions={[
                    "At Qase, you can meet with a lawyer to discuss your legal options and figure out how a lawyer can work with you to solve your problem. You will learn about the process, likely timelines and costs.",
                    "Interested in a particular area of law? Check out the practice areas, below.",
                ]}
                image_src={require("../../Images/Qase_online_collaboration_tools_0.png")}
                wave="wave-white"
            />

            <Fragment>
                {!isLoading && (
                    <section className="overflow-hidden">
                        <div className="position-relative">
                            <div className="divider divider-bottom wave-gray flip-y">
                                <svg
                                    width="102%"
                                    clipRule="evenodd"
                                    fillRule="evenodd"
                                    strokeLinejoin="round"
                                    strokeMiterlimit="2"
                                    viewBox="0 0 4992 596"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="m-4.167 88.792c177.084-45.221 336.804-67.83 479.167-67.83 213.542 0 765.625 88.7 989.583 118.267 223.959 29.567 380.529 32.571 545.138 26.088 309.029-12.175 482.642-60.871 772.571-76.525 142.4-7.688 502.116 9.779 883.679 90.437 381.562 80.659 701.391 201.75 888.891 219.142 125 11.592 271.988 5.796 440.971-17.392v-389.312h-5000v97.125z"
                                        fill="#fff"
                                        className="mono"
                                    ></path>
                                </svg>
                            </div>
                            <div className="q-pt-66 q-pb-98 q-pt-md-143 q-pb-md-173 position-relative">
                                <div className="container">
                                    <h1 className="q-color-midnightblue text-center q-mb-40">
                                        Learn more about the practice areas and
                                        types of services available on Qase:
                                    </h1>
                                    <div className="row">
                                        {practiceAreas &&
                                            practiceAreas
                                                .filter((item) => {
                                                    return item.body !== false;
                                                })
                                                .map((pr_area) => {
                                                    return (
                                                        <div
                                                            className="col-12 col-md-6 q-mb-1_3"
                                                            key={pr_area.id}
                                                        >
                                                            <PracticeAreaBlock
                                                                title={
                                                                    pr_area.name
                                                                }
                                                                description={
                                                                    pr_area.description
                                                                }
                                                                link_url={getPracticeAreaLink(
                                                                    pr_area
                                                                )}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}

                <section>
                    <div className="q-bg-whitesmoke q-pt-66 q-pb-98 q-pt-md-143 q-pb-md-173">
                        <div className="container">
                            <h1 className="text-center q-color-midnightblue q-mb-32 q-mb-md-40">
                                Lawyers on Qase also offer services in the
                                following areas of law:
                            </h1>

                            <div className="row  justify-content-between">
                                <div className="col-lg-4">
                                    <h3 className="q-mb-32">
                                        Housing and Real Estate
                                    </h3>
                                    <h3>Credit, Debt, Consumer or Civil</h3>
                                </div>
                                <div className="col-lg-4">
                                    <h3 className="q-mb-32">
                                        Business and Commercial
                                    </h3>
                                    <h3>Immigration and Human Rights</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>

            <GetStartedBlock is_flip={false} />
        </div>
    );
};

export default PracticeAreas;
