import React, { Fragment } from "react";

export default (path) => {
    switch (path) {
        case "/practice-area/family_law":
            return {
                title: "Need Legal Help on a Family Matter?",
                description:
                    "Book an online consultation with a family lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_FAMILY_LAW}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_FAMILY_LAW}`,
                css_class: "family_law",
                qase_solution:
                    "Family law issues can feel all-consuming because they are uniquely personal: they impact your most intimate relationships. Family law concerns can directly impact your and your family’s future, and compassionate and knowledgeable BC family lawyers can help you reduce stress and make the right decisions. If you believe you need a qualified BC family lawyer’s help with your legal problem, you can book a free 15-minute meeting with a lawyer practicing on Qase now, connect with them via video chat, have them assess your case and provide you with options to move forward.",
                faqs: [
                    {
                        title: "What are typical family law matters?",
                        content: (
                            <ul>
                                <li>Child Custody</li>
                                <li>Separation and Divorce</li>
                                <li>Spousal or Child Support</li>
                                <li>Pre-nuptial or Co-habitation agreements</li>
                                <li>Adoption</li>
                                <li>Domestic Violence</li>
                            </ul>
                        ),
                    },
                    {
                        title: "What is required to get a divorce in Canada?",
                        content: (
                            <p>
                                You must be separated for at least a year and go
                                through a legal process and get a court order
                                that says the marriage has ended.
                            </p>
                        ),
                    },
                    {
                        title:
                            "When there is a dispute, who makes decisions for a couple’s children?",
                        content: (
                            <p>
                                Biological parents generally have the right to
                                make decisions about their children. When there
                                is a custody issue, a court may decide where
                                children will primarily live, taking the best
                                interests of the child into account.
                            </p>
                        ),
                    },
                    {
                        title: "Am I in a common law relationship?",
                        content: (
                            <Fragment>
                                <p>
                                    In British Columbia, you are in a common law
                                    relationship if you:
                                </p>
                                <ul>
                                    <li>
                                        lived in a marriage-like relationship
                                        (where marriage-like is determined by
                                        considering many, many factors) for a
                                        period of at least two years; OR
                                    </li>
                                    <li>
                                        lived together for less than two years
                                        but had a child together.
                                    </li>
                                </ul>
                            </Fragment>
                        ),
                    },
                ],
            };

        case "/practice-area/child_custody":
            return {
                title: "Need Legal Help on a Child Custody matter?",
                description:
                    "Book an online consultation with a Child Custody lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_CHILD_CUSTODY}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_CHILD_CUSTODY}`,
                css_class: "child_custody",
                qase_solution:
                    "Child custody is about who makes decisions for children.  Biological parents generally have the legal right to make decisions about their children even if a child lives with one parent most or all the time. When there is a divorce or separation, a court may decide where children will primarily live, with the other parent having specific visitation times.  Sometimes, a court will order the parents to have an equal amount of time (joint custody).  In child custody cases, the court will first consider the best interests of the child.  A BC child custody lawyer can help create a plan to figure out the best arrangement for you and your children.  If you would like the help of a BC child custody lawyer, book a free 15-minute consultation with a lawyer practicing on Qase now and connect to discuss options.",
                faqs: [
                    {
                        title:
                            "How are child custody decisions generally made?",
                        content: (
                            <p>
                                Child custody decisions can be made by an
                                agreement of the parents, or if they cannot
                                agree, by the courts.{" "}
                            </p>
                        ),
                    },
                    {
                        title: "What are the types of child custody?",
                        content: (
                            <ul>
                                <li>
                                    Sole Custody: one parent has the legal
                                    responsibility for caring for and making all
                                    decisions about a child. The child lives
                                    primarily with that parent.
                                </li>
                                <li>
                                    Joint Custody: both parents share the rights
                                    and responsibilities for their children. The
                                    children can live with both parents or
                                    mostly with one parent, and both parents
                                    make decisions about the children.
                                </li>
                                <li>
                                    Split Custody: there are multiple children
                                    and the children are split between the
                                    parents; some live with one parent, the
                                    others with the other parent.
                                </li>
                            </ul>
                        ),
                    },
                    {
                        title: "What are interim custody orders?",
                        content: (
                            <p>
                                Interim orders provide temporary relief until
                                the full trial of your case is heard. A court
                                can award custody in a temporary manner, often
                                with one parent. This award is important because
                                courts normally continue this arrangement as
                                part of their final custody order if it is
                                working for the child.
                            </p>
                        ),
                    },
                ],
            };

        case "/practice-area/separation_and_divorce":
            return {
                title: "Need Legal Help on a Separation and Divorce matter?",
                description:
                    "Book an online consultation with a Family Law lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_SEPARATION_AND_DIVORCE}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_SEPARATION_AND_DIVORCE}`,
                css_class: "separation_and_divorce",
                qase_solution:
                    "Ending a relationship is difficult and can be overwhelming. To get a divorce in Canada, you must be separated for at least a year, go through a legal process, and get a court order saying the marriage has ended.  While a year separation is required, you should start making interim arrangements. Being in a common-law relationship means you have a lot of the legal rights and obligations of a married couple. Determining if you are in a common-law relationship can be important in understanding your rights and responsibilities. There are many approaches to resolving the complex issues related to separations and divorces. A BC divorce lawyer can help you navigate these complicated issues.   If you need a qualified BC divorce lawyer’s help, book a free 15-minute meeting with a lawyer practicing on Qase now to discuss your issue.",
                faqs: [
                    {
                        title: "What is separation?",
                        content: (
                            <p>
                                Separation is when a couple starts living apart
                                because their marriage is ending. There is no
                                such thing as a legal separation; spouses are
                                still married when they are separated. As soon
                                as couples start living apart, they are
                                considered separated. You don't need your
                                spouse's permission to start living separately.
                                And, some couples choose to separate but still
                                live in the same house.
                            </p>
                        ),
                    },
                    {
                        title: "What is a separation agreement?",
                        content: (
                            <p>
                                This is an agreement that creates rules around
                                your relationship while the details of a final
                                divorce are worked out. It can cover things like
                                support and who uses what property. If it is
                                difficult to come to an interim agreement, a
                                court or arbitrator can make an interim order
                                until the divorce is granted.
                            </p>
                        ),
                    },
                    {
                        title:
                            "What are the steps for getting a divorce in British Columbia?",
                        content: (
                            <div>
                                <p>
                                    To get divorced in British Columbia, you
                                    should:{" "}
                                </p>
                                <ul>
                                    <li>
                                        Figure out if it is a Fault or No-Fault
                                        divorce.
                                    </li>
                                    <li>
                                        Determine if it is a Contested or
                                        Uncontested divorce.
                                    </li>
                                    <li>
                                        Choose the type of application: Simple
                                        or Joint Application.
                                    </li>
                                    <li>Apply for the divorce.</li>
                                    <li>Write a separation agreement.</li>
                                    <li>
                                        Receive final approval from the court.
                                    </li>
                                </ul>
                            </div>
                        ),
                    },
                    {
                        title:
                            "How are common law relationships different than marriages?",
                        content: (
                            <p>
                                Being in a common-law relationship does not mean
                                that you are married, but it does mean you have
                                a lot of the legal rights and obligations of a
                                married couple. So, determining if you are in a
                                common-law relationship can be important in
                                understanding your rights and responsibilities.
                                While separation ends a common-law relationship,
                                it may not end the complex issues related to the
                                end of the relationship.
                            </p>
                        ),
                    },
                ],
            };

        case "/practice-area/spousal_support":
            return {
                title: "Need Legal Help on a Spousal Support matter?",
                description:
                    "Book an online consultation with a Family Law lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_SEPARATION_AND_DIVORCE}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_SEPARATION_AND_DIVORCE}`,
                css_class: "spousal_support",
                qase_solution:
                    "During the trying times of a divorce or separation, support is a major issue. Support means each parent's financial responsibility for the children and to the other spouse.  Children are legally entitled to child support from the parent who does not live with them. Spousal support helps ensure that a former partner does not struggle financially while becoming independent. Standard amounts of support are usually based on the Canadian Spousal Support Advisory Guidelines. Talk to a BC spousal support lawyer to help figure out if you should be paying or receiving support and whether the guidelines for support amounts make sense in your case.  To connect with a BC spousal support lawyer, book a free 15-minute meeting with a lawyer practicing on Qase now.",
                request_url: "separation_and_divorce",
                faqs: [
                    {
                        title: "What is the purpose of spousal support?",
                        content: (
                            <div>
                                <p>Spousal support is intended to:</p>
                                <ul>
                                    <li>
                                        Deal with economic issues a spouse may
                                        face because of the separation.
                                    </li>
                                    <li>
                                        Share financial obligations relating to
                                        children.
                                    </li>
                                    <li>
                                        Encourage financial self-sufficiency.
                                    </li>
                                </ul>
                            </div>
                        ),
                    },
                    {
                        title: "How is spousal support calculated?",
                        content: (
                            <p>
                                Standard amounts of support are usually based on
                                the Canadian Spousal Support Advisory
                                Guidelines. These amounts are usually based on
                                each party’s finances/incomes and the length of
                                the relationship.
                            </p>
                        ),
                    },
                    {
                        title: "How long does spousal support last?",
                        content: (
                            <p>
                                It depends, but it is often based on how long
                                you and your spouse were together. Generally,
                                spousal support lasts 6 to 12 months for every
                                year you were together. If you were married for
                                a long time or are older when you separate,
                                spousal support might not initially have a
                                specific end date.
                            </p>
                        ),
                    },
                ],
            };

        case "/practice-area/child_support":
            return {
                title: "Need Legal Help on a Child Support matter?",
                description:
                    "Book an online consultation with a Family Law lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_CHILD_CUSTODY}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_CHILD_CUSTODY}`,
                css_class: "child_support",
                qase_solution:
                    "During divorce or separation, support for minor children is extremely important.  Support involves a parent’s financial responsibility for the children.  The amount a party contributes is often based on his or her finances. Children are legally entitled to support from the parent who does not live with them. Standard amounts of support are usually based on the Canadian Spousal Support Advisory Guidelines which includes child support guidelines and formulas. A BC child support lawyer can help figure out if you should be paying or receiving support and whether the guidelines for support amounts make sense. If you believe you need a qualified BC child support lawyer’s help, you can book a free 15-minute meeting with a lawyer practicing on Qase now and connect with them via video chat to discuss your case.",
                request_url: "child_custody",
                faqs: [
                    {
                        title: "How is child support calculated?",
                        content: (
                            <p>
                                Standard amounts of support are usually based on
                                the Canadian Spousal Support Advisory
                                Guidelines. Child support payments consider
                                parents’ incomes, the number and ages of
                                children, and which parent each child lives
                                with.
                            </p>
                        ),
                    },
                    {
                        title:
                            "Am I responsible for supporting offspring who are adults?",
                        content: (
                            <p>
                                Potentially yes. If your child remains dependent
                                on your support under the BC Family Law Act,
                                then you are responsible.
                            </p>
                        ),
                    },
                    {
                        title: "What are Special & Extraordinary Expenses?",
                        content: (
                            <div>
                                <p>
                                    These are expenses that are above and beyond
                                    children’s day-to-day expenses. These may
                                    include:
                                </p>
                                <ul>
                                    <li>Day care expenses</li>
                                    <li>Health care expenses</li>
                                    <li>Tutoring</li>
                                    <li>Extracurricular activities</li>
                                    <li>Post-secondary expenses</li>
                                </ul>
                            </div>
                        ),
                    },
                ],
            };

        case "/practice-area/matrimonial_home":
            return {
                title: "Need Legal Help on a Matrimonial Home matter?",
                description:
                    "Book an online consultation with a Family Law lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_SEPARATION_AND_DIVORCE}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_SEPARATION_AND_DIVORCE}`,
                css_class: "matrimonial_home",
                qase_solution:
                    "Newly separated couples often must determine who should stay in their home.  When there is a disagreement about who should leave, a court can grant one party exclusive possession and use of the matrimonial home on a temporary basis, but courts are reluctant to eject someone from their own home.  Courts will require a party showing that both parties' remaining in the house is a “practical impossibility,” a high standard to meet.  Even if it is a practical impossibility, the court then must decide which party should be the preferred occupant.  A BC matrimonial home lawyer can help you understand these complicated issues.  Book a free 15-minute meeting with a BC matrimonial home lawyer practicing on Qase now and have them assess your case and provide options.",
                request_url: "separation_and_divorce",
                faqs: [
                    {
                        title: "What is family property?",
                        content: (
                            <p>
                                Family property is any property owned by one or
                                both spouses that is ordinarily used by anyone
                                in the immediate family for a family purpose.
                                Typically, when there is a separation, family
                                property is considered owned by both spouses and
                                shared equally.
                            </p>
                        ),
                    },
                    {
                        title: "Is a matrimonial home family property?",
                        content: (
                            <p>
                                Generally, yes. If one partner owned the home
                                before getting into the relationship, then it is
                                possible that only the accrued value while the
                                partners were together would be considered
                                family property. So, if a house purchased and
                                owned by one partner was worth $500k at the time
                                the partners came together and is worth $800k at
                                the time of separation, it’s possible that only
                                $300k of the property would be considered family
                                property.
                            </p>
                        ),
                    },
                    {
                        title: "May I sell the matrimonial home?",
                        content: (
                            <p>
                                Typically, you are not allowed to sell, rent or
                                even mortgage the family home unless your
                                partner agrees or you have a court order
                                permitting you to do so.
                            </p>
                        ),
                    },
                ],
            };

        case "/practice-area/employment_and_benefits":
            return {
                title: "Need Legal Help on an Employment Law matter?",
                description:
                    "Book an online consultation with an Employment lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_EMPLOYMENTS_AND_BENEFITS}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_EMPLOYMENTS_AND_BENEFITS}`,
                css_class: "employment_and_benefits",
                qase_solution:
                    "An employee’s job and compensation directly impact the employee’s future.  As an employer, a well-written, negotiated, enforceable arrangement with your employees can give you comfort in knowing where everyone stands.  Employment issues can include contracts, wrongful dismissal/discrimination, employment insurance benefits, harassment, workers compensation or help for people with disabilities.  Advocating for yourself or your company is complex and stressful. A compassionate and knowledgeable BC employment lawyer can help you reduce stress and make the right decisions. A BC employment lawyer can also help avoid pitfalls and costly disputes.  If you need help, you can book a free 15-minute consultation with a BC employment lawyer practicing on Qase now.",
                get_started:
                    "You are just a few clicks away from an employment lawyer who can help.",
                faqs: [
                    {
                        title: "What is an independent contractor?",
                        content: (
                            <div>
                                <p>
                                    An independent contractor is a person or
                                    entity contracted to perform work for or
                                    provide services to another entity as a
                                    non-employee. Independent contractors do not
                                    have the same rights or responsibilities as
                                    employees. Determining if you are an
                                    independent contractor is complicated, and
                                    the label “independent contractor” does not
                                    decide the issue. Factors to consider
                                    include:
                                </p>
                                <ul>
                                    <li>Who directs the work?</li>
                                    <li>Who profits or risks loss?</li>
                                    <li>Who provides the necessary tools?</li>
                                    <li>Is there an ongoing relationship?</li>
                                </ul>
                            </div>
                        ),
                    },
                    {
                        title: "What is constructive dismissal?",
                        content: (
                            <div>
                                <p>
                                    Constructive dismissal occurs when an
                                    employer fundamentally changes the work
                                    situation, and the employee does not accept
                                    the change. This situation may be treated
                                    the same as being fired, with the same
                                    notice requirements. Generally, there is
                                    constructive dismissal when:
                                </p>
                                <ul>
                                    <li>
                                        The employer fundamentally changes a key
                                        aspect of the work,
                                    </li>
                                    <li>
                                        The employee would not have expected it,
                                        and
                                    </li>
                                    <li>
                                        The employee does not agree to the
                                        change.
                                    </li>
                                </ul>
                            </div>
                        ),
                    },
                    {
                        title: "What is workplace discrimination?",
                        content: (
                            <p>
                                Canadians have the right to be treated fairly in
                                workplaces free from discrimination. The
                                Canadian Human Rights Act is legislation that
                                prohibits discrimination based on gender, race,
                                ethnicity, and other grounds. Also, Canada’s
                                Employment Equity Act and the Federal
                                Contractors Program require employers to take
                                active measures to improve the employment
                                opportunities for specific groups of people in
                                Canada. Harassment can be evidence of
                                discrimination and can lead to a discrimination
                                claim that goes to court or a tribunal.
                            </p>
                        ),
                    },
                ],
            };

        case "/practice-area/wrongful_dismissal_or_discrimination":
            return {
                title: "Need Legal Help on a Wrongful Dismissal Law matter?",
                description:
                    "Book an online consultation with an Employment lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_WRONGFUL_DISMISSAL}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_WRONGFUL_DISMISSAL}`,
                css_class: "wrongful_dismissal_or_discrimination",
                qase_solution:
                    "Dismissal from a job is extremely stressful for both the employer and employee. An employer cannot dismiss an employee without just cause or reasonable notice, must not force an employee to take a demotion without proper notice or cause, and must not act in bad faith when dismissing an employee. An employee must attend work, perform work competently, carry out the employer's legal orders, serve the employer honestly, and not create a conflict of interest. A knowledgeable BC wrongful dismissal lawyer can help guide you through the dismissal process and provide you with reliable advice that is relevant to your specific circumstances.  You can book a free 15-minute meeting with a BC wrongful dismissal lawyer practicing on Qase now to discuss your options.",
                get_started:
                    "You are just a few clicks away from an employment lawyer who can help.",
                testimonialsTitle: [
                    { firstSection: "Employment Law Client" },
                    { secondSection: "Employment Law Client" },
                    { thirdSection: "Employment Law Client" },
                ],
                testimonialsBody: [
                    {
                        firstSection:
                            "My lawyer was fabulous to talk with. She understood everything I’m going through.",
                    },
                    {
                        secondSection:
                            "My lawyer treated me in a caring and respectful way and took care of all responsibilities. Made me feel proud I chose her.",
                    },
                    {
                        thirdSection:
                            "I am very satisfied. I received a response immediately after requesting a consult and I am pleased with the work that has taken place in my case.",
                    },
                ],
                faqs: [
                    {
                        title:
                            "If an employer can avoid notice of termination with just cause, what is just cause?",
                        content: (
                            <div>
                                <p>Just cause can include:</p>
                                <ul>
                                    <li>Theft or other material dishonesty,</li>
                                    <li>Creating a conflict of interest,</li>
                                    <li>
                                        Use of drugs or alcohol in a way that
                                        interferes with work, or
                                    </li>
                                    <li>
                                        Intentional insubordination (including
                                        intentionally disobeying your boss or
                                        repeatedly breaking a clear rule or
                                        instructions).
                                    </li>
                                </ul>
                            </div>
                        ),
                    },
                    {
                        title:
                            "Without just cause, what is the minimum notice that an employer must give to an employee of termination?",
                        content: (
                            <div>
                                <p>
                                    In British Columbia, the notice must be
                                    reasonable. The minimum notice period (or
                                    payment instead) usually depends on length
                                    of time in the job.
                                </p>
                                <ul>
                                    <li>
                                        Less than three months in a row,
                                        employer does not need to give notice.
                                    </li>
                                    <li>
                                        After working three months in a row,
                                        employer must give at least one week’s
                                        notice (or payment in lieu).
                                    </li>
                                    <li>
                                        After working 12 months in a row,
                                        employer must give at least 2 weeks’
                                        notice or pay.
                                    </li>
                                    <li>
                                        After working three years in a row,
                                        employer must give at least 3 weeks’
                                        notice or pay.
                                    </li>
                                    <li>
                                        More than three years in a row, employer
                                        must give three weeks’ notice or pay
                                        plus a week for each additional year of
                                        service up to 8 weeks.
                                    </li>
                                </ul>
                            </div>
                        ),
                    },
                    {
                        title: "When is notice or pay not required?",
                        content: (
                            <div>
                                <p>
                                    Notice of termination or pay in lieu is not
                                    required when:
                                </p>
                                <ul>
                                    <li>Employee quits or retires</li>
                                    <li>The job is on-call, temporary work</li>
                                    <li>
                                        The job was for an agreed-upon length
                                    </li>
                                    <li>
                                        The job is for specific work to be
                                        completed in 12 months or less
                                    </li>
                                    <li>
                                        It is impossible for the employee to
                                        perform the work because of some
                                        unforeseen event
                                    </li>
                                    <li>
                                        It relates to a construction worker on a
                                        construction site where employer’s
                                        principal business is construction
                                    </li>
                                    <li>
                                        Employee refuses to take another,
                                        similar job
                                    </li>
                                    <li>
                                        Employee is a teacher employed by a
                                        board of school trustees
                                    </li>
                                </ul>
                            </div>
                        ),
                    },
                ],
            };

        case "/practice-area/contracts_(new_job_or_termination)":
            return {
                title: "Need Legal Help on a Labour Law matter?",
                description:
                    "Book an online consultation with an Employment lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_EMPLOYMENT_CONTRACTS}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_EMPLOYMENT_CONTRACTS}`,
                css_class: "contracts_new_job_or_termination",
                qase_solution:
                    "For most employees, work time makes up most of their waking hours. Employers can also use help navigating complicated regulations and situations.  Work-related issues can feel both personal and overwhelming. Labour issues can include contracts, wrongful dismissal/discrimination, employment insurance benefits, harassment, workers compensation or help for people with disabilities.  A compassionate and knowledgeable BC labour lawyer can help you reduce stress and make the right decisions. If you believe you need a qualified BC labour lawyer’s help with your problem, you can book a free 15-minute meeting with a lawyer practicing on Qase now, connect with them via video chat, have them assess your case and provide you with options to move forward.",
                get_started:
                    "You are just a few clicks away from an employment lawyer who can help.",
                faqs: [
                    {
                        title:
                            "When is the employer able to dismiss an employee?",
                        content: (
                            <p>
                                An employer must have just cause or give
                                reasonable notice (or payment in lieu) before
                                dismissing an employee. Also, an employer must
                                act in good faith when dismissing an employee.
                            </p>
                        ),
                    },
                    {
                        title:
                            "Can an employer force an employee to take a demotion?",
                        content: (
                            <p>Yes, but only with proper notice or cause.</p>
                        ),
                    },
                    {
                        title:
                            "Generally, what are employees’ responsibilities at work?",
                        content: (
                            <div>
                                <p>Employees must:</p>
                                <ul>
                                    <li>attend work,</li>
                                    <li>perform work competently,</li>
                                    <li>
                                        carry out the employer's legal orders,
                                    </li>
                                    <li>serve the employer honestly, and</li>
                                    <li>not create a conflict of interest.</li>
                                </ul>
                            </div>
                        ),
                    },
                ],
            };

        case "/practice-area/wills_and_estates":
            return {
                title: "Need Legal Help on a Wills and Estates matter?",
                description:
                    "Book an online consultation with a Wills and Estates lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_WILLS_AND_ESTATES}`,
                css_class: "wills_and_estates",
                qase_solution:
                    "Navigating the legal process on your own is complex and stressful.  It is even more difficult when you are either planning for a time when you are no longer able or are dealing with the loss of a loved one.  A compassionate and knowledgeable BC wills and estates lawyer can help you reduce stress and make the right decisions.  Wills and estates issues can include wills, trusts, guardianship, probate, estate administration and power of attorney. If you can use the help of a BC Wills and Estates lawyer to get your affairs in order, you can book a free 15-minute meeting with a lawyer practicing on Qase now to help you assess your situation.",
                get_started:
                    "You are just a few clicks away from a wills and estates lawyer who can help.",
                faqs: [
                    {
                        title: "Why make a will?",
                        content: (
                            <ul>
                                <li>
                                    You can make sure that the things you own go
                                    where you want.
                                </li>
                                <li>
                                    If you die without a will, there is no legal
                                    way of knowing what your wishes are. The
                                    court then must appoint someone to deal with
                                    your estate.
                                </li>
                                <li>
                                    Not clearly knowing what your wishes are
                                    means that you may be leaving behind
                                    uncertainty, conflict and extra costs for
                                    your relatives when you die.{" "}
                                </li>
                            </ul>
                        ),
                    },
                    {
                        title: "When should you make a will?",
                        content: (
                            <div>
                                <p>
                                    It is good practice for all adults to have a
                                    will. (Note that anyone 16 or older who is
                                    mentally capable may have an enforceable
                                    will.) It is especially important to draft
                                    or potentially revise a will when there are
                                    big life changes, such as:
                                </p>
                                <ul>
                                    <li>Getting married</li>
                                    <li>Having children</li>
                                    <li>Acquiring valuable assets</li>
                                </ul>
                            </div>
                        ),
                    },
                    {
                        title: "What are trusts?",
                        content: (
                            <p>
                                Trusts separate legal ownership from
                                use/enjoyment of property. They are baskets of
                                assets with specific instructions overseen by a
                                trustee. The beneficiaries of the trust receive
                                assets according to trust instructions.
                                Typically, trust assets are separate from a
                                person’s regular assets. So, trust assets
                                generally are not part of a person’s estate.
                            </p>
                        ),
                    },
                ],
            };

        case "/practice-area/estate_litigation":
            return {
                title: "Need Legal Help on a Estate Litigation matter?",
                description:
                    "Book an online consultation with a Wills and Estates lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_PROBATE_AND_ESTATE}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_PROBATE_AND_ESTATE}`,
                css_class: "estate_litigation",
                qase_solution:
                    "Handling an estate can create disputes about the validity of a will, some of its provisions or how an executor is handling the estate.  These disputes can irreparably damage family relationships.  Getting legal advice early can make sure that your rights are protected and lower the odds of lasting damage to your relationships.  There are also time limits that apply to contesting the will provisions, its administration, or the will itself.  If you believe you need a qualified BC estate litigation lawyer’s help with your legal problem, you can book a free 15-minute meeting with a lawyer practicing on Qase now.  They can let you know your options and how to move forward.",
                request_url: "probate_and_estate_administration",
                get_started:
                    "You are just a few clicks away from a wills and estates lawyer who can help.",
                faqs: [
                    {
                        title:
                            "Are courts allowed to alter the deceased’s will?",
                        content: (
                            <p>
                                Yes. Courts in B.C. have broad discretion to
                                alter wills for a variety of reasons.
                            </p>
                        ),
                    },
                    {
                        title: "What is “undue influence”?",
                        content: (
                            <p>
                                Undue influence is where someone is pressured
                                into making a will that reflects the interests
                                of the person influencing a vulnerable person. A
                                will created under undue influence may be
                                ignored by a court.
                            </p>
                        ),
                    },
                    {
                        title: "What is “adequate provision”?",
                        content: (
                            <p>
                                A court may effectively alter a will if the
                                will-maker dies leaving a will that does not
                                make “adequate provision” for the proper
                                maintenance and support of the will-maker’s
                                spouse or children. The court is then supposed
                                to order distribution that is just and
                                equitable. This determination is very fact
                                specific.
                            </p>
                        ),
                    },
                ],
            };

        case "/practice-area/prenuptial_and_co-habitation_agreements":
            return {
                title:
                    "Need Legal Help on a Prenuptial or Co-habitation Agreement?",
                description:
                    "Book an online consultation with a Family Law lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_PRENUPTIAL}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area=${process.env.REACT_APP_PRENUPTIAL}`,
                css_class: "prenuptial_and_co-habitation_agreements",
                qase_solution:
                    "Prenuptial and Cohabitation Agreements are contracts that set out what will happen to your property if your relationship ends. They can also help determine how property and debts will be managed during the relationship. These agreements are not required when joining with a partner, but they are sometimes advisable.  They give certainty and can help avoid future conflict.  While no one can force you to sign a marriage agreement, without an enforceable agreement, a court may determine how property is divided if the relationship ends.  Determining in advance what might happen can reduce conflict and save both parties time, effort, energy and legal fees.   You can book a free 15-minute meeting with a BC Prenuptial/Cohabitation Agreement lawyer practicing on Qase now, connect with them via video chat, and discuss options.",
                faqs: [
                    {
                        title:
                            "Can a Prenuptial or Cohabitation Agreement be changed?",
                        content: (
                            <p>
                                Yes. Like with other types of agreements,
                                Prenuptial and Cohabitation Agreements can be
                                changed with the agreement of both parties.
                            </p>
                        ),
                    },
                    {
                        title:
                            "When should I ask a lawyer for help with a marriage agreement?",
                        content: (
                            <div>
                                <p>
                                    Anyone can get a lawyer’s help, but it is
                                    especially important when:
                                </p>
                                <ul>
                                    <li>
                                        The financial situation of the partners
                                        is very unequal.
                                    </li>
                                    <li>
                                        There is a power imbalance between you
                                        and your partner.{" "}
                                    </li>
                                    <li>
                                        Your partner has presented an agreement
                                        drafted by a lawyer.{" "}
                                    </li>
                                    <li>
                                        You want to ensure that your agreement
                                        will be enforceable.
                                    </li>
                                    <li>
                                        Your financial situation is complicated.
                                    </li>
                                </ul>
                            </div>
                        ),
                    },
                ],
            };

        default:
            return {
                title: "Need Legal Help on a Family Matter?",
                description:
                    "Book an online consultation with a family lawyer in British Columbia",
                link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_FAMILY_LAW}`,
                mobile_link: `${process.env.REACT_APP_SITE_URL}/create_case?practice_area_p=${process.env.REACT_APP_FAMILY_LAW}`,
                css_class: "family_law",
                qase_solution:
                    "Family law issues can feel all-consuming because they are uniquely personal: they impact your most intimate relationships. Family law concerns can directly impact your and your family’s future, and compassionate and knowledgeable BC family lawyers can help you reduce stress and make the right decisions. If you believe you need a qualified BC family lawyer’s help with your legal problem, you can book a free 15-minute meeting with a lawyer practicing on Qase now, connect with them via video chat, have them assess your case and provide you with options to move forward.",
            };
    }
};
