import React from "react";
import { GetStartedBlock, BlueHero, ContactBlock } from "../../Components";

import "./Contact.scss";

const Contact = () => {
    return (
        <div id="contact-page">
            <BlueHero />
            <div className="q-py-25 q-py-40-md q-py-55-lg q-bg-whitesmoke">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-4">
                            <ContactBlock
                                iconType="email"
                                title="Email Support"
                                description="For help with your account or the Qase platform email us at:"
                                contact="support@qase.ca"
                                isEmail={true}
                            />
                            <ContactBlock
                                iconType="phone"
                                title="Phone Support"
                                description="To connect with us directly for help with your account or the Qase platform call:"
                                contact="+1 (844) 435-7658"
                            />
                            <ContactBlock
                                iconType="question"
                                title="General Inquiries"
                                description="To learn more about our services reach out to us at:"
                                contact="info@qase.ca"
                                isEmail={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <GetStartedBlock />
        </div>
    );
};

export default Contact;
